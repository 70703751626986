import queryClient from "components/utils/queryClient";
import { PublishedFolder } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const publishedFolderSchema: CustomSchema<PublishedFolder>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    uniqe: true,
    tooltip: "Name of this published folder."
  },
  {
    name: "path",
    label: "Path",
    component: "input",
    required: true,
    uniqe: true,
    tooltip: "Enter the local folder path for this folder.",
    dependencies: ["requestPath"],
  },
  {
    name: "requestPath",
    label: "RequestPath",
    component: "input",
    required: true,
    uniqe: true,
    tooltip:
      "Enter the request path for this folder. The request path must start with /. This is the path by which you will access the files.",
  },
  {
    name: "defaultDocument",
    label: "Default Document",
    component: "input",
    required: false,
    uniqe: false,
    tooltip:
      "Default documents allow you to load files when a user specifies the folder and not the document within a folder. This can be handy when a user visits /docs but does not specify /docs/index.html. Instead of returning a 404, you can return the index.html when the user specifies /docs",
  },
  {
    name: "authentication",
    label: "Authentication",
    component: "authSwitch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return (
        prevValues.authentication !== curValues.authentication ||
        curValues.authentication === true
      );
    },
    children: ({ getFieldValue }) => {
      const path = getFieldValue("path");
      let defaultRoles = queryClient
        .getQueryData<PublishedFolder[]>(`/publishedfolder`)
        ?.find((folder) => folder?.path === path)?.role;
      return getFieldValue("authentication") === true ? (
        <Field
          component="roleTags"
          name="role"
          label="Role"
          preserve={false}
          initialValue={defaultRoles || null}
          required={true}
          dependencies={["authentication"]}
          uniqe={false}
        />
      ) : null;
    },
  },
];
