import useAppStore from "components/context/app/Hooks";
import React from "react";
import { RoleGuardProps } from "types";

const RoleGuard: React.FunctionComponent<RoleGuardProps> = (props) => {
  const { requiredRoles, children, requiredAccessControls, accessControls, allowedWithOneWayGitSync, ignore, noDemo, fallback, allowWhenNotEditing } = props;
  const { userData, isEditing } = useAppStore();
  var found = false;

  if (ignore) {
    return children;
  }

  if (userData?.mode === "Demo" && noDemo) return <></>;
  if (userData?.oneWayGitSync && !allowedWithOneWayGitSync) return <></>;
  if (userData?.manualGitMode && !allowedWithOneWayGitSync && (!isEditing && !allowWhenNotEditing)) return <></>

  if (requiredRoles) {
    for (let i = 0; i < userData?.roles?.length; i++) {
      if (requiredRoles.indexOf(userData?.roles[i]) !== -1) {
        found = true;
        break;
      }
    }
  }

  if (!found && requiredAccessControls && accessControls) {
    found = ((accessControls & requiredAccessControls) === requiredAccessControls);
  }

  if (found) {
    return children;
  }

  return fallback || <></>
};

export default RoleGuard;
