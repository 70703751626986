import React from "react";
import { AppContext } from "./Context";
import { Update, Stats, Accessible, Settings, Feature, Sso, Identity, ResourceProperties, Role } from "../../../types";
import { useQuery } from "react-query3";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark.css`,
  light: `${process.env.PUBLIC_URL}/light.css`,
}

export default function AppProvider({ children }) {

  const [editing, setEditing] = React.useState(false);
  const { data: sso, isLoading: ssoLoading } = useQuery<Sso>("/sso", { staleTime: Infinity, cacheTime: Infinity });
  const { data: userData, isLoading } = useQuery<Accessible>("/accessible", { staleTime: Infinity, cacheTime: Infinity, retry: false });
  const { data: alive } = useQuery<any>("/alive", { staleTime: Infinity, cacheTime: Infinity, retry: false });

  const isBuildInRole = userData?.builtInRole === true;

  const { data: stats } = useQuery<Stats>("/stats", {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: isBuildInRole
  });
  const { data: version } = useQuery<string>("/version", { staleTime: Infinity, cacheTime: Infinity });
  const { data: buildVersion } = useQuery<any>("/version/build", { staleTime: Infinity, cacheTime: Infinity });
  const { data: update } = useQuery<Update>("/version/update", { staleTime: Infinity, cacheTime: Infinity });
  const { data: settings } = useQuery<Settings[]>("/settings", { enabled: isBuildInRole });
  const { data: publicSettings } = useQuery<Settings[]>("/settings/public", { enabled: !isBuildInRole });
  const { data: features } = useQuery<Feature[]>("/license/feature", { staleTime: Infinity, cacheTime: Infinity });
  const { data: identity } = useQuery<Identity>(`/identity/my`, { staleTime: Infinity, cacheTime: Infinity, retry: false });
  const { data: resources } = useQuery<ResourceProperties[]>(`/resource`, { staleTime: Infinity, cacheTime: Infinity, retry: false });
  const { data: roles } = useQuery<Role[]>(`/role`, { staleTime: Infinity, cacheTime: Infinity, retry: false });


  useQuery<any>("/gitstatus/edit", { staleTime: Infinity, cacheTime: Infinity, retry: false, onSuccess: (data) => { setEditing(data.editInProgress) }, onError: () => setEditing(false) });

  const licenseCheck = (feature: Feature) => features && features.indexOf(feature) !== -1;

  let defaultTheme = 'light';
  if (publicSettings && publicSettings[0].darkTheme) {
    defaultTheme = 'dark';
  }

  let currentTheme = localStorage.getItem("theme") ?? defaultTheme;

  return (
    <AppContext.Provider
      value={{
        stats,
        version,
        buildVersion: buildVersion?.version,
        update,
        userData,
        //@ts-ignore
        settings: settings || (publicSettings && publicSettings[0]),
        sso,
        licensed: licenseCheck,
        licensedFeatures: features,
        isUserDataLoading: isLoading,
        isSsoDataLoading: ssoLoading,
        isEditing: editing,
        identity,
        resources,
        developerLicense: alive?.developerLicense,
        roles
      }}
    >
      <ThemeSwitcherProvider
        defaultTheme={currentTheme}
        insertionPoint="inject-styles-here"
        themeMap={themes}
      >
        {children}
      </ThemeSwitcherProvider>

    </AppContext.Provider>
  );
}
