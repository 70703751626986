import React from "react";
import { Button, Card, Result, Table, Tooltip } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { SmartProperty } from "components/interfaces/components/SmartProperty";

export default function ApiTableOutput({ data, canReset, reset }): JSX.Element {
    const resetButton = canReset && <Tooltip title="Go Back">
        <Button onClick={reset} icon={<RollbackOutlined />} type="text"></Button>
    </Tooltip>

    if (data === undefined || !Array.isArray(data)) {
        return <Result status="warning" title="No Data" subTitle="This form didn't return any data to display" extra={canReset && <Tooltip title="Go Back">
            <Button onClick={reset} icon={<RollbackOutlined />}>Go Back</Button>
        </Tooltip>} />
    }

    const columns = Object.keys(data[0]).map((key) => ({
        key: key,
        dataIndex: key,
        title: key,
        render: (text, record) => {
            return record[key].type ? <SmartProperty {...record[key]} /> : text
        }
    }));

    return (
        <Card title="" extra={
            resetButton
        }>
            <Table dataSource={data} columns={columns}></Table>
        </Card>
    );
}
