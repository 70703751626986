import { Module } from "../../../types";
import { CustomSchema } from "./types";

export const moduleSchema: CustomSchema<Module>[] = [
    {
        name: "name",
        label: "Name",
        component: "input",
        required: true,
        rules: [{
            required: true,
            message: "Module name can't be empty."
        }],
        tooltip: "The name of the module."
    },
    {
        name: "version",
        label: "Version",
        component: "input",
        required: true,
        rules: [{
            required: true,
            message: "Module version can't be empty."
        }],
        tooltip: "The version of the module."
    },
];
