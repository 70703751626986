import { IComponentProperty, ComponentPropertyType } from './../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';

export const Props: Array<IComponentProperty> = [
    { "name": "id", "type": ComponentPropertyType.String, "required": true, "description": "The ID for this iFrame", displayName: "Id" },
    { "name": "url", "type": ComponentPropertyType.String, "required": false, "description": "The URL to display in the iFrame.", displayName: "URL" },
]

export const Constructor = () => {
    return {
        "$type": 'PowerShellUniversal.Components.IFrame, PowerShellUniversal',
        "text": "IFrame",
        "type": "IFrame",
        "id": uuidv4()
    }
}

export function Component(props) {
    return <iframe title={props.id} style={{ border: 0, height: '100%', width: '100%' }} src={props.url} id={props.id} sandbox="allow-same-origin allow-forms allow-scripts allow-popups-to-escape-sandbox allow-downloads"></iframe>
}