import { Typography } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import Markdown from 'react-markdown';

export const Props : Array<IComponentProperty>  = [
    { "name": "id", "type": ComponentPropertyType.String , "required": true, "description": "The ID for this typography", displayName: "Id" },
    { "name": "value", "type": ComponentPropertyType.String , "required": true, "description": "The text to display", displayName: "Text" },
    { "name": "code", "type": ComponentPropertyType.Boolean , "required": false, "description": "Display this text as code.", displayName: "Code" },
    { "name": "copyable", "type": ComponentPropertyType.Boolean , "required": false, "description": "Whether this text is copyable", displayName: "Copyable" },
    { "name": "level", "type": ComponentPropertyType.Select , "required": false, "description": "The title level", displayName: "Level", options: [
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" }	
    ] },
    {
        "name": "contentType", "type": ComponentPropertyType.Select, "required": false, "description": "The formatting of the content.", displayName: "Content Type", options: [
            { value: "plaintext", text: "Plaintext" },
            { value: "markdown", text: "Markdown" },
            { value: "html", text: "HTML" },
        ], category: 'Basic'
    },
]

export const Constructor = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Title, PowerShellUniversal',
        "value": "Title",
        "type": "Title",
        "contentType": "plaintext",
        "id": uuidv4(),
        "level": "1"
    }
}

export function Component(props) {
    let { value, contentType } = props;

    if (contentType === 'markdown') {
        value = <Markdown children={value} />
    }
    else if (contentType === 'html') {
        value = <div dangerouslySetInnerHTML={{ __html: value }}></div>;
    }

    return <Typography.Title code={props.code} level={props.level}>{value}</Typography.Title>
}