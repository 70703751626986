import { Endpoint } from "../../../types";
import { CustomSchema } from "./types";

export const endpointSchema: CustomSchema<Endpoint>[] = [
  {
    name: "url",
    label: "URL",
    hasFeedback: true,
    dependencies: ["method"],
    component: "input",
    tooltip:
      "Enter the URL of this endpoint. You can start segments of the URL with a colon (e.g. '/user/:id') to denote variables.",
    required: true,
    uniqe: true,
  },
  {
    name: "description",
    label: "Description",
    component: "input",
    required: false,
    uniqe: false,
    hideInCreateMode: true
  },
  {
    name: "documentation",
    label: "Documentation",
    component: "endpointDoc",
    tooltip: "The endpoint documentation definition to include this endpoint in.",
    required: false,
    uniqe: false,
    hideInCreateMode: true
  },
  {
    name: "path",
    label: "Path",
    component: "input",
    tooltip: "An optional path to store the content for this endpoint",
    required: false,
    uniqe: false,
  },
  {
    name: "method",
    label: "Method",
    component: "methodSelector",
    required: false,
    uniqe: false,
  },
  {
    name: "authentication",
    label: "Authentication",
    component: "authSwitch",
    valuePropName: 'checked',
    required: false,
    uniqe: false,
    hideInCreateMode: true
  },
  {
    name: "environment",
    label: "Environment",
    component: "environment",
    initialValue: null,
    tooltip: "The environment to run this endpoint in. If not specified, the API will be run in the default environment.",
    hideInCreateMode: true
  },
  {
    name: "role",
    component: "roleTags",
    label: "Role",
    hideInCreateMode: true,
  },
  {
    component: "errorAction",
    name: "errorAction",
    label: "Error Action",
    initialValue: 0,
    tooltip: "The error action to take when the endpoint throws an error. If set to Stop, the script will return a 500 error when a terminating error is thrown.",
    hideInCreateMode: true
  },
  {
    name: "tag",
    label: "Tags",
    component: "tag",
    hideInCreateMode: true,
    initialValue: []
  },
  {
    name: "persistentLog",
    label: "Persistent Log",
    component: "switch",
    tooltip: "Stores API log messages in the database. Only use this setting for debugging purposes as it can greatly reduce performance.",
    valuePropName: 'checked',
    hideInCreateMode: true,
    required: false,
    uniqe: false,
  },
];
