import React, { useState } from "react";
import { Menu, Layout, Space, Typography, Button, Dropdown, Tag, Tooltip } from "antd";
import { UserOutlined, LogoutOutlined, BarcodeOutlined, CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, LockOutlined, SettingOutlined } from "@ant-design/icons";
import { useQuery } from "react-query3";
import Logo from "../ui/Logo";
import useAppStore from "../context/app/Hooks";
import queryClient from "components/utils/queryClient";
import ThemeToggle from "components/ui/theme/themeToggle";
import Notification from "components/ui/Notification";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useNavigate } from "react-router";
import RoleGuard from "components/standalone/role-guard";
import { Feature } from "types";
import { Link } from "react-router-dom";
import { toRelativeUrl } from "components/utils/utils";
import { useTranslation } from "react-i18next";
import GitEditButton from "components/git/GitEditButton";
import ResetPasswordModal from "components/modals/reset-password-modal";

const UAMenuHeader = ({ title = null, minimal = false }) => {
  const { t } = useTranslation();
  const { userData, version, buildVersion, licensed, update, identity, developerLicense } = useAppStore();
  const navigate = useNavigate();
  const { data: authType } = useQuery<string>("/authmethod");
  const { refetch: logoff } = useQuery<string>("/signout", {
    enabled: false,
    onSuccess: () => queryClient.clear(),
  });

  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);

  const { currentTheme } = useThemeSwitcher()
  const onClick = (key) => {
    if (key === "signout") {
      logoff().then(() => (window.location.href = authType === "Cookies" ? toRelativeUrl("/login") : toRelativeUrl("/loggedout")))
    }

    if (key === "tokens") {
      navigate(toRelativeUrl("/admin/security/tokens"))
    }

    if (key === "settings") {
      navigate(toRelativeUrl("/admin/settings/my"))
    }

    if (key === "resetPassword") {
      setResetPasswordOpen(true);
    }
  };

  return (
    <Layout.Header

      style={{
        color: currentTheme === 'light' ? "#000000" : "#ffffff",
        backgroundColor: currentTheme === 'light' ? '#fff' : '#1f1f1f',
        padding: "0px 8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Logo mode={userData?.mode} title={title} />
      <Space>
        {!minimal && <>
          <GitEditButton />
          <RoleGuard requiredRoles={["Administrator"]} allowWhenNotEditing allowedWithOneWayGitSync>
            <Typography.Text
              style={{ verticalAlign: "sub", marginRight: 24 }}
            >
              {developerLicense && <Tooltip title={t('common.developerLicenseDescription')}>
                <Tag color="warning" icon={<CheckCircleOutlined />}>{t('common.developerLicense')}</Tag></Tooltip>}
              <Link to={toRelativeUrl("/admin/settings/license")}>
                {licensed(Feature.Automation) ?
                  <Tag title={t('common.licensed')} color="success" icon={<CheckCircleOutlined />}>{t('common.licensed')}</Tag> :
                  <Tooltip title={t('licensing.tooltipDescription')}><Tag title={t('common.unlicensed')} color="error" icon={<CloseCircleOutlined />}>{t('common.unlicensed')}</Tag></Tooltip>}
              </Link>
            </Typography.Text>
          </RoleGuard>
          <RoleGuard requiredRoles={["Administrator"]}>
            {update?.version && update.version !== 'none' && <Typography.Text
              style={{ verticalAlign: "sub", marginRight: 24 }}
            >
              <a href="https://ironmansoftware.com/powershell-universal/downloads" target="_blank" rel="noreferrer">
                <Tag icon={<DownloadOutlined />} color="processing">{`New Version Available - ${update.version}`}</Tag>
              </a>

            </Typography.Text>}
          </RoleGuard>
          {userData?.mode === "Demo" && <Typography.Text
            style={{ verticalAlign: "sub", marginRight: 24 }}
          >
            <a href="https://ironmansoftware.com/powershell-universal" target="_blank" rel="noreferrer">
              <Tag color="success">Demo - Click to Learn More About PowerShell Universal</Tag>
            </a>

          </Typography.Text>}
          <Typography.Text
            style={{ verticalAlign: "sub", marginRight: 24 }}
          >
            <Tooltip title={buildVersion}>
              <Tag color="gold">{version}</Tag>
            </Tooltip>
          </Typography.Text>
        </>}

        <ThemeToggle
          checkedChildren={t('themes.dark')}
          unCheckedChildren={t('themes.light')}
          size="small"
        />
        {(userData?.roles?.includes("Administrator") || userData?.roles?.includes("Operator")) && <Notification />}
        <Dropdown
          placement="bottomCenter"
          //@ts-ignore
          destroyPopupOnHide={true}
          trigger={["click"]}
          overlay={() => (
            <Menu onClick={({ key }) => onClick(key)}>
              <Menu.Item key="settings" icon={<SettingOutlined />}>
                My Settings
              </Menu.Item>
              {(userData?.roles?.includes("Administrator") || userData?.roles?.includes("Operator") || userData?.roles?.includes("Execute")) && (
                <Menu.Item key="tokens" icon={<BarcodeOutlined />}>
                  Tokens
                </Menu.Item>
              )}
              {identity?.localAccount && <Menu.Item key="resetPassword" icon={<LockOutlined />}>
                Reset Password
              </Menu.Item>}
              <Menu.Item key="signout" icon={<LogoutOutlined />}>
                Sign Out
              </Menu.Item>
            </Menu>
          )}
        >
          <Button type="text" icon={<UserOutlined />}>{userData?.username}</Button>
        </Dropdown>
        <ResetPasswordModal identity={identity} open={resetPasswordOpen} setOpen={setResetPasswordOpen} />
      </Space>
    </Layout.Header>
  );
};

export default UAMenuHeader;
