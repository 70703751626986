import { environmentSchame } from "./environmentSchame";
import { scriptSchame } from "./scriptSchame";
import { tagSchame } from "./tagSchame";
import { triggerSchame } from "./triggerSchame";
import { licenseSchema } from "./licenseSchema";
import { roleSchema } from "./roleSchema";
import { identitySchema } from "./identitySchema";
import { tokenSchema } from "./tokenSchema";
import { scheduleSchema } from "./scheduleSchema";
import { variableSchema } from "./variableSchema";
import { endpointSchema } from "./endpointSchema";
import { ratelimitSchema } from "./ratelimitSchema";
import { ratelimitSettingsSchema } from "./ratelimitSettingsSchema";
import { frameworkSchema } from "./frameworkSchema";
import { dashboardSchema } from "./dashboardSchema";
import { publishedFolderSchema } from "./publishedFolderSchema";
import { feedbackSchema } from "./feedbackSchema";
import { templateSchema } from "./templateSchema";
import { pageSchema } from "./pageSchema";
import { folderSchema } from "./folderSchema";
import { authenticationSchema } from "./authenticationSchema";
import { moduleSchema } from "./moduleSchema";
import { terminalSchema } from "./terminalSchema";
import { hotkeySchema } from "./hotkeySchema";
import { accessControlSchema } from "./accessControlSchema";
import { systemEventSchema } from "./systemEventSchema";
import { fileAssociationSchema } from "./fileAssociationSchema";
import { protocolHandlerSchema } from "./protocolHandlerSchema";
import { translationSchema } from "./translationSchema";
import { gitSettingsSchema } from "./gitSettingsSchema";
import { gitCommentSchema } from "./gitCommitSchema";
import { dashboardPageSchema } from "./dashboardPageSchema";
import { resetPasswordSchema } from "./resetPasswordSchema";
import { endpointDocumentationSchema } from "./endpointDocumentationSchema";
import { eventHubSchema } from "./eventHubSchema";
import { loggingTargetSchema } from "./loggingTargetSchema";
import { computerGroupSchema } from './computerGroupSchema'


export const schemas = {
  authentication: authenticationSchema,
  endpoint: endpointSchema,
  tag: tagSchame,
  script: scriptSchame,
  trigger: triggerSchame,
  environment: environmentSchame,
  license: licenseSchema,
  role: roleSchema,
  identity: identitySchema,
  token: tokenSchema,
  schedule: scheduleSchema,
  variable: variableSchema,
  ratelimit: ratelimitSchema,
  ratelimitSettings: ratelimitSettingsSchema,
  dashboardframework: frameworkSchema,
  dashboard: dashboardSchema,
  publishedfolder: publishedFolderSchema,
  jobfeedback: feedbackSchema,
  template: templateSchema,
  page: pageSchema,
  folder: folderSchema,
  module: moduleSchema,
  terminal: terminalSchema,
  hotkey: hotkeySchema,
  accessControl: accessControlSchema,
  systemEvent: systemEventSchema,
  fileAssociation: fileAssociationSchema,
  protocolHandler: protocolHandlerSchema,
  translation: translationSchema,
  gitSettings: gitSettingsSchema,
  gitCommit: gitCommentSchema,
  dashboardPage: dashboardPageSchema,
  password: resetPasswordSchema,
  endpointDocumentation: endpointDocumentationSchema,
  loggingTarget: loggingTargetSchema,
  eventhub: eventHubSchema,
  computerGroup: computerGroupSchema
};
