import React from "react";
import { useQuery } from "react-query3";
import { JobPipelineOutput, JobPipelineOutputData } from "types";
import { Button, Card, Result, Spin, Table, Tooltip } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { SmartProperty } from "components/interfaces/components/SmartProperty";

export default function TableOutput({ jobId, canReset, reset }): JSX.Element {
    const { data, isLoading } = useQuery<any>(
        `/job/${jobId}/pipelineOutput`,
        {
            select: (data: JobPipelineOutput[]): JobPipelineOutputData =>
                data[0]?.jsonData !== null || data[0]?.jsonData !== undefined
                    ? JSON.parse(data[0]?.jsonData)
                    : null,
            enabled: !!jobId,
            refetchOnWindowFocus: false,
        }
    );

    const resetButton = canReset && <Tooltip title="Go Back">
        <Button onClick={reset} icon={<RollbackOutlined />} type="text"></Button>
    </Tooltip>

    if (isLoading && !data) {
        return <Spin tip="Loading table..." />
    }

    if (data[0]?.children === undefined) {
        return <Result status="warning" title="No Data" subTitle="This form didn't return any data to display" extra={canReset && <Tooltip title="Go Back">
            <Button onClick={reset} icon={<RollbackOutlined />}>Go Back</Button>
        </Tooltip>} />
    }

    const columns = data[0].children.map((child) => ({
        key: child.name,
        dataIndex: child.name,
        title: child.name,
        render: (text, record) => {
            if (record[child.name]) {
                return record[child.name].type ? <SmartProperty {...record[child.name]} /> : text
            }
            return "";
        }
    }));

    const rows = data.map((item) => {
        const row = {};
        item.children.forEach((child) => {
            if (child.children) {
                let smartProp = {}
                if (child.children) {
                    smartProp = child.children.reduce((acc, curr) => {
                        acc[curr.name] = curr.value
                        return acc
                    }, {})
                }
                row[child.name] = smartProp;
            } else {
                row[child.name] = child.value;
            }

        });
        return row;
    });

    return (
        <Card title="" extra={
            resetButton
        }>
            <Table dataSource={rows} columns={columns}></Table>
        </Card>
    );
}
