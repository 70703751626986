import { SystemEvent, Script } from "../../../types";
import { CustomSchema } from "./types";
import queryClient from "../../utils/queryClient";
import { Field } from "./Form";
import { Select } from "antd";

function setComponentByParameterType(parameter) {
    return parameter.displayType === 0
        ? "input"
        : parameter.displayType === 1
            ? "inputNumber"
            : parameter.displayType === 2
                ? "switch"
                : parameter.displayType === 3
                    ? "datetimePicker"
                    : "credential";
}

export const systemEventSchema: CustomSchema<SystemEvent>[] = [
    {
        name: "name",
        label: "Name",
        component: "input",
        tooltip: "Name of this event.",
    },
    {
        name: "type",
        label: "Event Type",
        component: "systemEventType",
        required: true,
        uniqe: false,
        tooltip: "The system event type"
    },
    {
        name: "condition",
        label: "Condition",
        component: "textarea",
        rules: [{
            required: true,
            message: "Condition can't be empty."
        }]
    },
    {
        name: "script",
        label: "Script",
        tooltip: "Select the script to create schedule for it.",
        component: "scriptSelect",
        required: true,
        uniqe: false
    },
    {
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.script !== curValues.script;
        },
        children: ({ getFieldValue }) => {
            let selectedScript = getFieldValue("script");
            let environment = getFieldValue("environment");

            let script = queryClient
                .getQueryData<Script[]>("/script")
                ?.find((script) => script.fullPath === selectedScript);
            return script?.environment === null ? (
                <Field
                    component="environment"
                    name="environment"
                    label="Environment"
                    initialValue={environment || "Default"}
                    preserve={false}
                />
            ) : null;
        },
    },
    {
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.script !== curValues.script;
        },
        children: ({ getFieldValue }) => {
            let selectedScript = getFieldValue("script");
            let script = queryClient
                .getQueryData<Script[]>("/script")
                ?.find((script) => script.fullPath === selectedScript);
            return script?.credential === null ? (
                <Field
                    component="credential"
                    name="credential"
                    label="Credential"
                    initialValue="Default"
                    preserve={false}
                />
            ) : null;
        },
    },
    {
        required: false,
        uniqe: false,
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.script !== curValues.script;
        },
        children: ({ getFieldValue }) => {
            let selectedScript = getFieldValue("script");
            let script = queryClient
                .getQueryData<Script[]>("/script")
                ?.find((script) => script.fullPath === selectedScript);
            return script?.scriptParameters?.length > 0
                ? script?.scriptParameters?.map((parameter) => {
                    return parameter.displayType === 4 ? (
                        <Field
                            key={parameter.id}
                            name={parameter.name}
                            label={parameter.name}
                            required={parameter.required}
                            initialValue={parameter.defaultValue}
                            tooltip={parameter.helpText}
                            preserve={false}
                        >
                            <Select>
                                {parameter.validValues?.map((validValue) => {
                                    return (
                                        <Select.Option key={validValue} value={validValue}>
                                            {validValue}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Field>
                    ) : parameter.displayType === 6 ? (
                        <Field
                            key={parameter.id}
                            name={parameter.name}
                            label={parameter.name}
                            required={parameter.required}
                            initialValue={parameter.defaultValue}
                            tooltip={parameter.helpText}
                            preserve={false}
                        >
                            <Select mode="tags" tokenSeparators={[",", ";"]} />
                        </Field>
                    ) : (
                        <Field
                            key={parameter.id}
                            name={parameter.name}
                            label={parameter.name}
                            required={parameter.required}
                            initialValue={parameter.defaultValue}
                            tooltip={parameter.helpText}
                            component={setComponentByParameterType(parameter)}
                            preserve={false}
                        />
                    );
                })
                : null;
        },
    },
];
