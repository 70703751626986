import React from "react";
import { Select } from "antd";
import { useQuery } from "react-query3";

export default function ComputerSelectBox(props) {
  const { data: computerQueues } = useQuery<string[]>("/computer/queue");

  return (
    <Select {...props} >
      <Select.Option key="Any" value="Any">Any Computer</Select.Option>
      <Select.Option key="All" value="All">All Computers</Select.Option>
      {computerQueues?.map((comp) => (
        <Select.Option key={comp} value={comp}>{comp?.replace("custom-", "")}</Select.Option>
      ))}
    </Select>
  )
}
