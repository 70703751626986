import { Tag as TagType } from "../../types";
import queryClient from "./queryClient";

export function convertFromAntTag(tags) {
  return tags !== null
    ? queryClient
      .getQueryData<TagType[]>("/tag")
      ?.filter((tag) =>
        tags?.find((vt: any) => tag.name === vt.label)
      )
    : [];
}
