import { Identity } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const resetPasswordSchema: CustomSchema<Identity>[] = [
    {
        name: "oldPassword",
        label: "Old Password",
        component: "password",
        required: true,
        rules: [{
            required: true,
            message: "Old password can't be empty."
        }]
    },
    {
        uniqe: false,
        noStyle: true,
        required: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.localAccount !== curValues.localAccount;
        },
        children: ({ getFieldValue }) => {
            return (
                <Field
                    component="password"
                    name="password"
                    label="Password"
                    preserve={false}
                    tooltip="The password for the local account."
                />
            );
        },
    },
    {
        uniqe: false,
        noStyle: true,
        required: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.localAccount !== curValues.localAccount;
        },
        children: ({ getFieldValue }) => {
            return (
                <Field
                    component="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    preserve={false}
                    tooltip="Confirm the password for the local account."
                />
            );
        },
    },
];
