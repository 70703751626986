import { Liquid } from '@ant-design/charts';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { Link } from 'react-router-dom';
import useDataSource from '../DataSource';

export const Props: Array<IComponentProperty> = [
    { "name": "id", "type": ComponentPropertyType.String, "required": true, "description": "The ID for this chart", displayName: "Id" },
    { "name": "dataSource", "type": ComponentPropertyType.DataSource, "required": true, "description": <>The <Link to="/admin/automation/scripts" target="_blank">script</Link> or <Link to="/admin/apis/endpoints" target="_blank">API</Link> data source for this chart.</>, displayName: "Data Source", category: "Data Source" },
    { "name": "color", "type": ComponentPropertyType.String, "required": false, "description": "The color of the line.", displayName: "Color" },
]

export const Constructor = () => {
    return {
        "$type": 'PowerShellUniversal.Components.LiquidChart, PowerShellUniversal',
        "type": "Liquid",
        "id": uuidv4(),
    }
}

export function Component(props) {
    const { data, isLoading } = useDataSource(props);

    if (isLoading || !data) {
        return <div />
    }

    return <Liquid color={props.color} loading={isLoading || !data} percent={Number.parseFloat(data[0])} wave={{ length: 128 }} />
}