import { Button, Card, Checkbox, Form, Input, Result, Space, Spin, Typography, Divider, Select, InputNumber, Rate, DatePicker, TimePicker, Switch, Alert } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import React, { useContext } from 'react';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Job, JobStatus } from 'types';
import { useQuery } from 'react-query3';
import SimpleJobOutput from 'components/jobs/simpleOutput';
import ApiSimpleOutput from 'components/api/apiSimpleOutput';
import JobProgress from 'components/jobs/components/Progress';
import FeedbackModal from 'components/jobs/components/FeedbackModal';
import TableOutput from 'components/jobs/tableOutput';
import ApiTableOutput from 'components/api/apiTableOutput';
import { Modal } from 'components/ui/Modal';
import * as Icons from '@ant-design/icons';
import { VariableContext } from '../VariableContext';


export const PSUFormProps: Array<IComponentProperty> = [
    { "name": "title", "type": ComponentPropertyType.String, "required": true, "description": "The title of this form.", displayName: "Title", category: "General" },
    { "name": "target", "type": ComponentPropertyType.Target, "required": true, "description": <p>The <Link to="/admin/automation/scripts" target="_blank">script</Link> or <Link to="/admin/apis/endpoints" target="_blank">API</Link> to execute when submitting this form. Must return true or false.</p>, displayName: "Target", category: "Target" },
    { "name": "validationApi", "type": ComponentPropertyType.Api, "required": false, "description": <p>An <Link to="/admin/apis/endpoints" target="_blank">API</Link> endpoint that validates this field before it's submitted.</p>, displayName: "Validation API", category: "General" },
    {
        "name": "resultType", "type": ComponentPropertyType.Select, "required": false, "description": "Changes how the result of the form is shown.", displayName: "Result Type", category: "General", options: [
            { "text": "None", "value": 0 },
            { "text": "Text", "value": 1 },
            { "text": "Table", "value": 2 },
        ]
    },
    { "name": "fields", "type": ComponentPropertyType.FormFields, "required": false, "description": "Fields for the user to enter into the form.", displayName: "Form Fields", category: "Fields" },
    { "name": "successTitle", "type": ComponentPropertyType.String, "required": false, "description": "The title to display after the form is submitted successfully.", displayName: "Success Title", category: "Text" },
    { "name": "id", "type": ComponentPropertyType.String, "required": true, "description": "The ID for this form", displayName: "Id", category: "Other" },
    { "name": "description", "type": ComponentPropertyType.String, "required": true, "description": "The description for this form.", displayName: "Description", category: "General" },
    { "name": "successDescription", "type": ComponentPropertyType.String, "required": false, "description": "The description to display after the form is submitted successfully.", displayName: "Success Description", category: "Text" },
    { "name": "failureTitle", "type": ComponentPropertyType.String, "required": false, "description": "The title to display after the form fails to submit.", displayName: "Failure Title", category: "Text" },
    { "name": "failureDescription", "type": ComponentPropertyType.String, "required": false, "description": "The description to display after the form fails to submit.", displayName: "Failure Description", category: "Text" },
    { "name": "showOutput", "type": ComponentPropertyType.Boolean, "required": false, "description": "Show the output while the script is running. ", displayName: "Show Output", category: "General" },
    { "name": "showProgress", "type": ComponentPropertyType.Boolean, "required": false, "description": "Show the progress from the target. Progress only works with scripts", displayName: "Show Progress", category: "General" },
    { "name": "saveButtonText", "type": ComponentPropertyType.String, "required": false, "description": "The text to display in the save button.", displayName: "Save Button Text", category: "Text" },
    { "name": "saveButtonIcon", "type": ComponentPropertyType.Icon, "required": false, "description": "The icon to display in the save button.", displayName: "Save Button Icon", category: "Text" },
    { "name": "waitingOnFeedbackText", "type": ComponentPropertyType.String, "required": false, "description": "The text to display when waiting on feedback.", displayName: "Waiting on Feedback Text", category: "Text" },
    { "name": "canReset", "type": ComponentPropertyType.Boolean, "required": false, "description": "The user can reset the form to run it again.", displayName: "Can Reset", category: "General" },
    { "name": "refreshComponents", "type": ComponentPropertyType.RefreshComponents, "required": false, "description": "Components to refresh after this form is completed.", displayName: "Refresh Components" },

]

export const NewPSUForm = () => {
    return {
        "type": "Form",
        "id": uuidv4(),
        "$type": 'PowerShellUniversal.Components.Form, PowerShellUniversal',
        'title': "Form"
    }
}

export default function PSUForm(props) {
    const { refreshComponents } = useContext(VariableContext);
    const [success, setSuccess] = React.useState(false);
    const [failure, setFailure] = React.useState(false);
    const [failureMessage, setFailureMessage] = React.useState('');
    const [validationError, setValidationError] = React.useState('');

    const [refetchInterval, setRefetchInterval] = React.useState<false | number>(false)
    const [jobId, setJobId] = React.useState<number | null>(null);
    const [apiOutput, setApiOutput] = React.useState<string>('');
    const [apiData, setApiData] = React.useState<any>(null);

    const reset = () => {
        setSuccess(false);
        setFailure(false);
        setJobId(null);
    }

    const { data: job } = useQuery<Job>(`/job/${jobId}`, {
        enabled: jobId !== null,
        refetchOnWindowFocus: false,
        refetchInterval,
        onSuccess: (job) => {
            if (job.status !== JobStatus.Running && job.status !== JobStatus.Canceling && job.status !== JobStatus.Queued) {
                setRefetchInterval(false)
            }

            if (job.status === JobStatus.Completed || job.status === JobStatus.Warning) {
                setSuccess(true);
                setFailure(false);

                refreshComponents(props.refreshComponents);
            }

            if (job.status === JobStatus.Failed) {
                setSuccess(false);
                setFailure(true);
                setFailureMessage(job.statusDescription || props.failureDescription || 'Your form failed to submit.');
            }
        }
    });

    const renderField = (field) => {
        var itemType = null;
        switch (field.type) {
            case "hidden":
                itemType = <Input hidden={true} />
                break;
            case "textbox":
                itemType = <Input />
                break;
            case "checkbox":
                itemType = <Checkbox />
                break;
            case "select":
                itemType = <Select>
                    {field.values && field.values.map((value) => { return <Select.Option value={value}>{value}</Select.Option> })}
                </Select>
                break;
            case "number":
                itemType = <InputNumber />
                break;
            case "rating":
                itemType = <Rate allowHalf />
                break;
            case "date":
                itemType = <DatePicker />
                break;
            case "time":
                itemType = <TimePicker />
                break;
            case "switch":
                itemType = <Switch />
                break;
            case "password":
                itemType = <Input.Password />
                break;
        }

        return (
            <Form.Item
                label={field.displayName || field.name}
                name={field.name}
                tooltip={field.tooltip}
                help={field.help}
                rules={[{ required: field.required }]}
                hidden={field.type === "hidden"}
                valuePropName={field.type === "checkbox" || field.type === "switch" ? "checked" : "value"}
            >
                {itemType}
            </Form.Item>
        )
    }



    const onSubmit = (form) => {
        if (!props.target) return;


        var options;
        var url;
        if (props.target.type === "api") {
            url = props.target.name;
            options = {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        }

        if (props.target.type === "script") {
            url = `/api/v1/script/path/${props.target.name}`;
            options = {
                method: 'POST',
                body: JSON.stringify({
                    jobParameters: Object.keys(form).map(p => {
                        const isPassword = props.target.fields.find(f => f.type === "password" && f.name === p);

                        return {
                            name: p,
                            displayType: isPassword ? 8 : 0,
                            type: isPassword ? 'System.Security.SecureString' : 'System.Object',
                            value: form[p]
                        }
                    }),
                    environment: props.target.environment,
                    credential: props.target.runAs,
                    parentJobId: -1
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        }

        const send = () => {
            setValidationError(null)
            fetch(url, options)
                .then(resp => {
                    if (resp.status !== 200) {
                        setFailure(true);
                    }
                    else {
                        if (props.target.type === "script") {
                            resp.text().then(text => {
                                setJobId(Number.parseInt(text))
                                setRefetchInterval(1000);
                            })
                        } else {
                            resp.text().then(text => {
                                setApiOutput(text);
                                try {
                                    setApiData(JSON.parse(text));
                                } catch { }
                            })
                            setSuccess(true);

                            refreshComponents(props.refreshComponents);
                        }
                    }
                })
                .catch(err => {
                    setFailure(true);
                })
        }

        if (props.validationApi) {
            const validationOptions = {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            fetch(props.validationApi, validationOptions).then(resp => {
                if (resp.status !== 200) {
                    setValidationError("Form data is not valid.");
                }
                else {
                    resp.text().then(text => {
                        var data = JSON.parse(text);
                        if (Array.isArray(data)) {
                            data = data[0]
                        }
                        if (data.success) {
                            send();
                        }
                        else {
                            setValidationError(data.errorMessage)
                        }
                    })
                }
            })
        }
        else {
            send();
        }
    }

    if (success) {
        switch (props.resultType) {
            default:
            case 0:
                return <Result status="success" title={props.successTitle || "Success!"} subTitle={props.successDescription || "Your form was successfully submitted."} extra={
                    props.canReset && <Button onClick={reset} icon={<RollbackOutlined />}>Go Back</Button>
                } />
            case 1:
                return props.target.type === "script" ?
                    <SimpleJobOutput job={job} refetchInterval={false} canReset={props.canReset} reset={reset} /> :
                    <ApiSimpleOutput output={apiOutput} canReset={props.canReset} reset={reset} />
            case 2:
                return props.target.type === "script" ?
                    <TableOutput jobId={job.id} canReset={props.canReset} reset={reset} /> :
                    <ApiTableOutput data={apiData} canReset={props.canReset} reset={reset} />
        }
    }

    if (failure) {
        return <Result status="error" title={props.failureTitle || "Error!"} subTitle={failureMessage} extra={
            props.canReset && <Button onClick={reset} icon={<RollbackOutlined />}>Go Back</Button>
        } />
    }

    if (job?.status === JobStatus.Running || job?.status === JobStatus.Canceling || job?.status === JobStatus.Queued) {
        return (
            <>
                <Space align="center" direction="vertical" style={{ width: '100%' }}>
                    <Spin size="large" tip="Submitting form..." />
                </Space>
                {props.showProgress && job !== null && <JobProgress job={job} />}
                {props.showOutput && job !== null && <><Divider>Output</Divider><SimpleJobOutput job={job} refetchInterval={refetchInterval} /></>}
            </>
        )
    }

    if (job?.status === JobStatus.WaitingOnFeedback) {
        return <Result status="info" title={"Waiting on Feedback"} extra={
            <Modal>
                <FeedbackModal job={job} feedbackComplete={() => setRefetchInterval(1000)} />
            </Modal>
        }></Result>
    }

    const initalValues = {};
    if (props.target && props.target.fields) {
        props.target.fields.filter(m => m.value != null).forEach(field => {
            initalValues[field.name] = field.value;
        })
    }

    return (
        <Card style={{ minHeight: '10px', minWidth: '10px' }}>
            <Typography.Text strong>{props.title}</Typography.Text>
            <Typography.Paragraph>{props.description}</Typography.Paragraph>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={onSubmit}
                labelAlign="left"
                initialValues={initalValues}
            >
                {props.target && props.target.fields && props.target.fields.map(renderField)}
                {validationError && validationError !== '' && <Alert message={validationError} type="error" showIcon style={{ marginBottom: '10px' }} />}
                <Form.Item wrapperCol={{ span: 16 }}>
                    <Button type="primary" htmlType="submit" icon={props.saveButtonIcon ? React.createElement(Icons[props.saveButtonIcon]) : <SaveOutlined />}>
                        {props.saveButtonText || "Save"}
                    </Button>
                </Form.Item>
            </Form>
        </Card>

    )
}
