import React from "react";
import { Layout } from "antd";
import Loader from "./utils/Loader";
import Scrollbars from "rc-scrollbars";

export default function PrimaryLayout({ children }) {
  return (
    <Scrollbars hideTracksWhenNotNeeded autoHide disableDefaultStyles>
      <Layout style={{ height: '100%' }}>
        <Layout.Content style={{ padding: 24 }}>
          <Loader>{children}</Loader>
        </Layout.Content>
      </Layout>
    </Scrollbars>
  );
}
