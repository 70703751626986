import { Role } from "../../../types";
import { CustomSchema } from "./types";

export const roleSchema: CustomSchema<Role>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    rules: [{
      required: true,
      message: "Name can't be empty."
    }]
  },
  {
    name: "claimType",
    label: "Claim Type",
    tooltip: "The claim type that will be used to identify the role. The policy script will be ignored if this is set.",
    component: "claimType",
    required: false,
  },
  {
    name: "claimValue",
    label: "Claim Value",
    tooltip: "The claim value that will be used to identify the role. The policy script will be ignored if this is set.",
    component: "claimValue",
    required: false,
  },
  {
    name: "roles",
    label: "Nested Roles",
    tooltip: "Additional roles that will be granted when this role is provided to an identity.",
    component: "roleTags",
    required: false,
    uniqe: false,
  },
  {
    name: "defaultRoute",
    label: "Default Route",
    tooltip: "The default route to redirect users in this role to.",
    component: "input",
    required: false,
  },
  {
    name: "description",
    label: "Description",
    component: "input",
    required: false,
  },
  {
    name: "disabled",
    label: "Disabled",
    tooltip: "Whether this role is disabled.",
    component: "switch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
  },
];
