import React, { FC } from 'react'
import update from 'immutability-helper'
import { DesignerCard } from './DesignerCard'
import PSUComponent, { NewComponent } from './Component'
import { EditorContext } from './EditorContext'
import Toolbox from './Toolbox';
import { WidthProvider, Responsive } from "react-grid-layout";
require('react-grid-layout/css/styles.css');
require('react-resizable/css/styles.css');

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export interface Item {
  id: number
  text: string
}

export interface ContainerState {
  cards: Item[]
}

export interface DesignSurfaceProps {
  components: any;
  toolboxVisible: boolean;
  setToolboxVisible: (visible: boolean) => void;
  setComponents: (components: any) => void;
  layout: any;
  setLayout: (layout: any) => void;
}

export const DesignSurface: FC<DesignSurfaceProps> = ({ components, setComponents, toolboxVisible, setToolboxVisible, layout, setLayout }) => {
  const [locked, setLocked] = React.useState(false);

  const renderCard = (component: any) => {

    var dataGrid = null;
    if (component.x) {
      dataGrid = component;
    }

    return (
      <div key={component.id} id={component.id} data-grid={dataGrid} style={{ overflow: component.type === "table" ? 'auto' : 'inherit' }}>
        <DesignerCard
          component={component}
        >
          <PSUComponent {...component} />
        </DesignerCard>
      </div>

    )
  }

  const setComponent = (component: any) => {
    var index = components.findIndex(m => m.id === component.id)

    var updateOptions = {}
    updateOptions[index] =
    {
      $set: {
        ...components[index],
        ...component,
      }
    }
    setComponents(update(components, updateOptions));
  }

  const removeComponent = (component: any) => {
    setComponents(components.filter(m => m.id !== component.id))
  }

  const addComponent = (type: string) => {
    const component = NewComponent(type);
    components.push(component);
    setComponents(components);
    layout.lg.push({ i: component.id, w: 10, h: 10, x: 0, y: 0 })
    setLayout(layout);
    setToolboxVisible(false);
  }

  const layoutChanged = (layout: any, layouts: any) => {
    setLayout(layouts)
  }

  const lockDesigner = (locked: boolean) => {
    setLocked(locked);
  }

  return (
    <EditorContext.Provider value={{
      components,
      setComponent,
      removeComponent,
      lockDesigner
    }}>
      <ResponsiveReactGridLayout useCSSTransforms={false} isDraggable={!locked} isResizable={!locked} layouts={layout} onLayoutChange={layoutChanged} measureBeforeMount={true} rowHeight={10} cols={{ lg: 50, md: 10, sm: 6, xs: 4, xxs: 2 }}>
        {components.map((card) => renderCard(card))}
      </ResponsiveReactGridLayout>
      <Toolbox visible={toolboxVisible} onClose={() => setToolboxVisible(false)} addComponent={addComponent} />
    </EditorContext.Provider>
  )

}
