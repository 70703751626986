import React from 'react';
import { Variable } from 'types';
import { IComponentProperty } from './ComponentProperty';

export type PageVariable = {
    name: string;
    value: string;
    session: boolean;
    context: string;
}

export type DataSourceComponent = {
    id: string;
    url: string;
}

export const VariableContextDefault = {
    variables: new Array<PageVariable>(),
    credentials: new Array<Variable>(),
    registerComponent: (id: string, url: string) => { },
    getComponents: (): Array<string> => { return [] },
    unregisterComponent: (id: string) => { },
    refreshComponents: (id: Array<string>) => { },
    setVariable: (variable: PageVariable) => { },
    replaceVariables: (context, props, properties: Array<IComponentProperty>): any => { return {} }
}

export const VariableContext = React.createContext(VariableContextDefault);