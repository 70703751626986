import React from "react";
import { Job, JobLog, JobStatus } from "types";
import { useQuery } from "react-query3";
import { Button, Card, message, Tooltip } from "antd";
import { CopyFilled, RollbackOutlined } from "@ant-design/icons";
import useClipboard from "use-clipboard-hook";

export default function SimpleJobOutput({ refetchInterval, job, canReset, reset }: { refetchInterval: false | number, job: Job, canReset?: boolean, reset?: () => void }) {
    const { data, isLoading } = useQuery<JobLog>(
        `/job/${job?.runId || job?.id}/log?timestamp=false&stream=false`, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        refetchInterval
    }
    );

    let jobLogContent = "";
    if ((job?.status === JobStatus.Completed || job?.status === JobStatus.Failed || job?.status === JobStatus.Error || job?.status === JobStatus.Warning) && data === null && !isLoading) {
        jobLogContent = "# This job produced no output"
    } else {
        jobLogContent = data?.log;
    }

    if (jobLogContent === "# Waiting for job data...") {
        jobLogContent = "";
    }

    const { copy } = useClipboard({
        onSuccess: () => message.success(`Copied.`),
    });

    const onCopy = () => {
        copy(jobLogContent);
    }

    return (
        <Card title="" bodyStyle={{
            height: "75%",
        }} style={{
            height: '100%'
        }} extra={
            <>
                <Tooltip title="Copy">
                    <Button
                        type="text"
                        icon={<CopyFilled />}
                        onClick={() => onCopy()}
                    />
                </Tooltip>
                {canReset && <Tooltip title="Go Back">
                    <Button onClick={reset} icon={<RollbackOutlined />} type="text"></Button>
                </Tooltip>}
            </>
        }>

            <pre style={{
                height: '100%'
            }} >{jobLogContent}</pre>
        </ Card>

    );
}
