import React from "react";
import { Input as AntInput } from 'antd';
const Input = React.lazy(() => import("antd/es/input"));
const Password = React.lazy(() => import("antd/es/input/Password"));
const Checkbox = React.lazy(() => import("antd/es/checkbox"));
const Select = React.lazy(() => import("antd/es/select"));
const Switch = React.lazy(() => import("antd/es/switch"));
const InputNumber = React.lazy(() => import("antd/es/input-number"));
const ErrorActionSelectBox = React.lazy(() => import("../../ui/ErrorActionSelectBox"));
const TagSelectBox = React.lazy(() => import("../../ui/SelectTagBox"));
const SingleTagSelectBox = React.lazy(() => import("../../ui/SingleTagSelectBox"));
const EnvironmentSelectBox = React.lazy(() => import("../../ui/EnvironmentSelectBox"));
const VariableSelectBox = React.lazy(() => import("../../ui/VariableSelectBox"));
const ColorPicker = React.lazy(() => import("../../ui/ColorPicker"));
const TagPreview = React.lazy(() => import("../../ui/TagPreview"));
const EventTypeSelectBox = React.lazy(() => import("../../ui/EventTypeSelectBox"));
const RoleSelectBox = React.lazy(() => import("../../ui/RoleSelectBox"));
const StaticRoleSelectBox = React.lazy(() => import("../../ui/StaticRoleSelectBox"));
const RoleTagSelectBox = React.lazy(() => import("../../ui/RoleTagSelectBox"));
const ExpirationTimeSelectBox = React.lazy(() => import("../../ui/ExpirationTimeSelectBox"));
const ScheduleTypeSelector = React.lazy(() => import("../../ui/ScheduleTypeSelector"));
const SimpleTimeSelectBox = React.lazy(() => import("../../ui/SimpleTimeSelectBox"));
const DateTimePicker = React.lazy(() => import("../../ui/DateTimePicker"));
const CredentialsSelectBox = React.lazy(() => import("../../ui/CredentialsSelectBox"));
const SecretTypeSelector = React.lazy(() => import("../../ui/SecretTypeSelector"));
const VariableTypeSelector = React.lazy(() => import("../../ui/VariableTypeSelector"));
const VaultSelectBox = React.lazy(() => import("../../ui/VaultSelectBox"));
const MethodSelectBox = React.lazy(() => import("../../ui/methodSelectBox"));
const AuthSwitch = React.lazy(() => import("../../ui/authSwitch"));
const PeriodSelectBox = React.lazy(() => import("../../ui/PeriodSelectBox"));
const SelectAsTags = React.lazy(() => import("../../ui/SelectAsTags"));
const UploadFile = React.lazy(() => import("../../ui/Upload"));
const ScriptSelect = React.lazy(() => import("../../ui/ScriptSelect"));
const KeySelect = React.lazy(() => import("../../ui/keySelect"));
const ModifierKeySelect = React.lazy(() => import("../../ui/modifierKeySelect"));
const VariableTypeSelect = React.lazy(() => import("../../ui/VariableTypeSelect"));
const TimezoneSelect = React.lazy(() => import("../../ui/Timezone"));
const ComputerSelect = React.lazy(() => import("../../ui/ComputerSelectBox"));
const AccessControlTypeSelector = React.lazy(() => import("../../ui/accessControlTypeSelector"));
const AccessControlObjectTypeSelector = React.lazy(() => import("../../ui/accessControlObjectTypeSelector"));
const DashboardTemplateSelect = React.lazy(() => import("../../ui/dashboardTemplateSelect"));
const SystemEventTypeSelector = React.lazy(() => import("../../ui/systemEventType"));
const FolderSelect = React.lazy(() => import("../FolderSelect"));
const EndpointDocSelect = React.lazy(() => import("../EndpointDocumentationSelectBox"));
const ModuleSelector = React.lazy(() => import("../ModuleSelector"));
const CommandSelector = React.lazy(() => import("../CommandSelector"));
const ScriptModuleSelector = React.lazy(() => import("../ScriptModuleSelector"));
const ClaimTypeSelector = React.lazy(() => import("../ClaimTypeSelector"));
const ClaimValueSelector = React.lazy(() => import("../ClaimValueSelector"));
const DashboardSelector = React.lazy(() => import("../dashboardSelector"));
const FontAwesomeIcon = React.lazy(() => import("../FontAwesomeIcon"));
const DashboardPageTemplateSelect = React.lazy(() => import("../dashboardPageTemplateSelect"));

// mapping of our components
export default function getComponent(component: string) {
  switch (component) {
    case "input":
      return Input;
    case "textarea":
      return AntInput.TextArea;
    case "inputNumber":
      return InputNumber;
    case "password":
      return Password;
    case "checkbox":
      return Checkbox;
    case "switch":
      return Switch;
    case "select":
      return Select;
    case "scriptSelect":
      return ScriptSelect;
    case "selectTag":
      return (props) => <Select {...props} mode="tags" />;
    case "colorPicker":
      return ColorPicker;
    case "tag":
      return TagSelectBox;
    case "singleTag":
      return SingleTagSelectBox;
    case "errorAction":
      return ErrorActionSelectBox;
    case "environment":
      return EnvironmentSelectBox;
    case "variables":
      return VariableSelectBox;
    case "preview":
      return TagPreview;
    case "eventType":
      return EventTypeSelectBox;
    case "role":
      return RoleSelectBox;
    case "staticRole":
      return StaticRoleSelectBox;
    case "roleTags":
      return RoleTagSelectBox;
    case "expirationTime":
      return ExpirationTimeSelectBox;
    case "typeSelector":
      return ScheduleTypeSelector;
    case "simpleTime":
      return SimpleTimeSelectBox;
    case "datetimePicker":
      return DateTimePicker;
    case "credential":
      return CredentialsSelectBox;
    case "secretTypeSelector":
      return SecretTypeSelector;
    case "variableTypeSelector":
      return VariableTypeSelector;
    case "vault":
      return VaultSelectBox;
    case "methodSelector":
      return MethodSelectBox;
    case "authSwitch":
      return AuthSwitch;
    case "period":
      return PeriodSelectBox;
    case "selectAsTags":
      return SelectAsTags;
    case "upload":
      return UploadFile;
    case "key":
      return KeySelect;
    case "modifierKey":
      return ModifierKeySelect;
    case "variableTypeSelect":
      return VariableTypeSelect;
    case "timeZone":
      return TimezoneSelect;
    case "computer":
      return ComputerSelect;
    case "accessControlType":
      return AccessControlTypeSelector;
    case "accessControlObjectType":
      return AccessControlObjectTypeSelector;
    case "dashboardTemplateSelect":
      return DashboardTemplateSelect;
    case "systemEventType":
      return SystemEventTypeSelector;
    case "folder":
      return FolderSelect;
    case "endpointDoc":
      return EndpointDocSelect;
    case "module":
      return ModuleSelector;
    case "command":
      return CommandSelector;
    case "scriptModule":
      return ScriptModuleSelector;
    case "claimType":
      return ClaimTypeSelector;
    case "claimValue":
      return ClaimValueSelector;
    case "dashboard":
      return DashboardSelector;
    case "fontAwesomeIcon":
      return FontAwesomeIcon;
    case "dashboardPageTemplate":
      return DashboardPageTemplateSelect;
    default:
      throw new Error(
        `component (${component}) need to be one of the following : 
          input,
          inputNumber, 
          password, 
          checkbox, 
          switch, 
          select,
          colorPicker, 
          tag, 
          errorAction, 
          environment,
          variables,
          preview,
          eventType`
      );
  }
}
