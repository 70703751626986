import { convertFromAntTag } from "./convertFromAntTag";
import { hasTags } from "./utils";
import { convertToAntTag } from "./convertToAntTag";
import React from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

function timespanToInt(timespan: string) {
  const groups = timespan.match(/(?:(\d{1,3}).)?(\d{1,2}):(\d{1,2}):(\d{1,2})/);
  const dayjsDuration = dayjs.duration({
    days: parseInt(groups[1]!),
    hours: parseInt(groups[2]!),
    minutes: parseInt(groups[3]!),
    seconds: parseInt(groups[4]!)
  });

  return dayjsDuration.asSeconds();
}

export default function useNormalizeData() {
  const normalizedRecord = React.useCallback((record) => {
    if (record?.resourceInfo?.parent === "/tag" && record?.color?.hex) {
      return {
        ...record,
        color: record?.color?.hex,
      };
    }

    if (record?.resourceInfo?.parent === "/schedule") {
      return {
        ...record,
        delay: record?.delay ? timespanToInt(record?.delay) : null,
        environment: record?.environment === "Default" ? null : record?.environment,
        computer: record?.computer ?? "Any",
        type: record?.oneTime
          ? "oneTime"
          : record?.continuous
            ? "continuous"
            : "cron",
      };
    }

    if (record?.resourceInfo?.parent === "/variable") {
      return {
        ...record,
        secret: record?.vault ? true : false,
        variableType: record?.vault ? "secret" : "simple",
      };
    }

    if (record?.resourceInfo?.parent === "/endpoint") {
      if (hasTags(record)) {
        return {
          ...record,
          scriptModule: record?.module ? "module" : "script",
          tag: record?.tag[0]?.label ? convertFromAntTag(record?.tag) : convertToAntTag(record?.tag),
          role: record?.authentication ? record?.role : null,
          environment: record?.environment === "Default" ? null : record?.environment,
        };
      }
      return {
        ...record,
        scriptModule: record?.module ? "module" : "script",
        role: record?.authentication ? record?.role : null,
        environment: record?.environment === "Default" ? null : record?.environment,
      };
    }

    if (record?.resourceInfo?.parent === "/dashboard") {
      if (hasTags(record)) {
        return {
          ...record,
          scriptModule: record?.module ? "module" : "script",
          tag: record?.tag[0]?.label ? convertFromAntTag(record?.tag) : convertToAntTag(record?.tag),
          autoStart: !record?.disableAutoStart,
          disableAutoStart: !record?.autoStart,
          environment: record?.environment === "Default" ? null : record?.environment,
          role: record?.authenticated ? record?.role : null,
          dashboardFramework: record.dashboardFramework?.name
            ? record.dashboardFramework?.id
            : { id: record.dashboardFramework },
        };
      }
      return {
        ...record,
        scriptModule: record?.module ? "module" : "script",
        autoStart: !record?.disableAutoStart,
        disableAutoStart: !record?.autoStart,
        environment: record?.environment === "Default" ? null : record?.environment,
        role: record?.authenticated ? record?.role : null,
        dashboardFramework: record.dashboardFramework?.name
          ? record.dashboardFramework?.id
          : { id: record.dashboardFramework },
      };
    }

    if (record?.resourceInfo?.parent === "/publishedfolder") {
      return {
        ...record,
        role: record?.authentication ? record?.role : null,
      };
    }

    if (record?.resourceInfo?.parent === "/script") {
      if (hasTags(record)) {
        return {
          ...record,
          scriptModule: record?.module ? "module" : "script",
          environment: record?.environment === "Default" ? null : record?.environment,
          tag: record?.tag[0]?.label
            ? convertFromAntTag(record?.tag)
            : convertToAntTag(record?.tag),
        };
      }
      return {
        ...record,
        environment: record?.environment === "Default" ? null : record?.environment,
      };
    }

    if (record?.resourceInfo?.parent === "/accessControl") {
      return record;
    }

    if (record?.resourceInfo?.parent === "/ratelimit") {
      if (record?.action === "update") {
        return { ...record }
      }
      return {
        ...record,
        method: record?.endpoint.split(':')[0].split(","),
        endpoint: record?.endpoint.split(':')[1]
      };
    }


    if (hasTags(record)) {
      return {
        ...record,
        tag: record?.tag[0]?.label
          ? convertFromAntTag(record?.tag)
          : convertToAntTag(record?.tag),
      };
    }
    return record;
  }, []);


  return normalizedRecord;
}
