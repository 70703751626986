import { GitStatus } from "../../../types";
import { CustomSchema } from "./types";

export const gitCommentSchema: CustomSchema<GitStatus>[] = [
    {
        name: "resultMessage",
        label: "Comment",
        component: "input",
        tooltip: 'Comment on your changes commit.',
        required: true,
        uniqe: true,
        rules: [{
            required: true,
            message: "Comment cannot be empty."
        }]
    }
]