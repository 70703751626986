import { EventHub } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";
import queryClient from "components/utils/queryClient";

export const eventHubSchema: CustomSchema<EventHub>[] = [
    {
        name: "name",
        label: "Name",
        component: "input",
        required: false,
        uniqe: false,
    },
    {
        name: "description",
        label: "Description",
        component: "input",
        required: false,
        uniqe: false,
    },
    {
        name: "authenticated",
        label: "Authentication",
        component: "authSwitch",
        valuePropName: 'checked',
        required: false,
        uniqe: false,
    },
    {
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.authenticated !== curValues.authenticated || curValues.authenticated === true
        },
        children: ({ getFieldValue }) => {
            const name = getFieldValue("name")
            let defaultRoles = queryClient.getQueryData<EventHub[]>(`/eventhub`)?.find(endpoint => endpoint?.name === name)?.role

            return getFieldValue("authenticated") === true ? (
                <Field
                    component="roleTags"
                    name="role"
                    label="Role"
                    preserve={false}
                    required={true}
                    dependencies={['authenticated']}
                    initialValue={defaultRoles || null}
                    uniqe={false}
                />
            ) : null;
        },
    },
];
