import { QueryClientProvider } from "react-query3";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import queryClient from "./utils/queryClient";
import AppProvider from "./context/app/Provider";
import AuthorizedView from "./AuthorizedView";
import Login from "./pages/login";
import MaintenanceMode from "./pages/MaintenanceMode";
import PageDashboard from "./interfaces/PageDashboard";
import Loader from "./utils/Loader";
import UnAuthorizedPage from "./pages/UnAuthorizedPage";
import { toRelativeUrl } from "./utils/utils";
import './localization/i18n';
import LoggedOutPage from "./pages/loggedOut";
import AuthFailure from "./pages/auth-failure";

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <Loader>
          <BrowserRouter>
            <Routes basename={toRelativeUrl("")}>
              <Route path="admin/*" element={<AuthorizedView />} />
              <Route path="login/*" element={<Login />} />
              <Route path="maintenance" element={<MaintenanceMode />} />
              <Route path="unauthorized" element={<UnAuthorizedPage />} />
              <Route path="loggedout" element={<LoggedOutPage />} />
              <Route path="failed-login" element={<AuthFailure />} />
              <Route path="*" element={<PageDashboard />} />
            </Routes>
          </BrowserRouter>
        </Loader>
      </AppProvider>
    </QueryClientProvider>
  );
};

export default App;
