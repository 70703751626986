import React from 'react';
import { Form, Input, Modal } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import PSUIcon from './properties/icon';
import DataSourceSelector from './properties/dataSource';

export default function PageProperties(props) {
  const [form] = Form.useForm();
  const { visible, setVisible, page, setPage } = props;

  const onFinish = (values: any) => {
    var updatedPage = { ...page, ...values };
    setPage(updatedPage);
    setVisible(false);
  }


  const submit = () => {
    form.validateFields()
      .then(values => {
        form.resetFields();
        onFinish(values);
      })
  }

  return (
    <>
      <Modal
        title="Properties"
        width="50vw"
        closable={false}
        maskClosable={false}
        onCancel={() => setVisible(false)}
        onOk={submit}
        okText="Save"
        visible={visible}
      >
        <Form
          name="pageProperties"
          initialValues={page}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item name="description" label="Description" tooltip="The description of this page.">
            <Input />
          </Form.Item>
          <PSUIcon name="icon" label="Icon" tooltip="The icon to display in the navigation for this page." />
          <Form.Item name="showInNavigation" label="Show in Navigation" tooltip="Show this page in the navigation menu." valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item name="showNavigation" label="Show Navigation" tooltip="Show the navigation menu on this page." valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item name="hideHeader" label="Hide Header" tooltip="Hide header bar." valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item name="hideTitle" label="Hide Title" tooltip="Hide the title and description of the page.." valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <DataSourceSelector component={page} form={form} />
        </Form>
      </Modal>
    </>
  );
};
