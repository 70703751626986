import React from "react";
import { Button, Card, Checkbox, Form, Input, Menu, Popconfirm, Select, Space, Typography } from "antd";
import { DeleteOutlined, FileAddFilled } from '@ant-design/icons';

export default function FormFields(props) {
    const [fieldNames, setFieldNames] = React.useState(props.component.target ? props.component.target.fields.map(x => x.name) : []);
    const [types, setTypes] = React.useState(props.component.target ? props.component.target.fields.map(x => x.type) : []);
    const [tab, setTab] = React.useState('');

    const setName = (index, name) => {
        setFieldNames({ ...fieldNames, [index]: name });
    }

    const setType = (index, type) => {
        setTypes({ ...types, [index]: type });
    }

    const formField = (field, remove) => {
        return (
            <>
                <Form.Item
                    label="Name"
                    name={[field.name, 'name']}
                    fieldKey={[field.fieldKey, 'name']}
                    rules={[{ required: true, message: 'Missing name' }]}
                >
                    <Input onChange={(e) => setName(field.name, e.target.value)} />
                </Form.Item>
                <Form.Item
                    label="Display Name"
                    name={[field.name, 'displayName']}
                    fieldKey={[field.fieldKey, 'displayName']}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Type"
                    name={[field.name, 'type']}
                    fieldKey={[field.fieldKey, 'type']}
                    rules={[{ required: true, message: 'Missing type' }]}
                >
                    <Select onChange={e => setType(field.name, e.toString())}>
                        <Select.Option value="checkbox">Checkbox</Select.Option>
                        <Select.Option value="date">Date</Select.Option>
                        <Select.Option value="hidden">Hidden</Select.Option>
                        <Select.Option value="number">Number</Select.Option>
                        <Select.Option value="select">Select</Select.Option>
                        <Select.Option value="switch">Switch</Select.Option>
                        <Select.Option value="textbox">Textbox</Select.Option>
                        <Select.Option value="time">Time</Select.Option>
                        <Select.Option value="rating">Rating</Select.Option>
                        <Select.Option value="password">Password</Select.Option>
                    </Select>
                </Form.Item>
                {types[field.name] === 'select' && (
                    <Form.Item
                        label="Available Values"
                        name={[field.name, 'values']}
                        fieldKey={[field.fieldKey, 'values']}>
                        <Select mode="tags" />
                    </Form.Item>
                )}
                <Form.Item
                    label="Default Value"
                    name={[field.name, 'value']}
                    fieldKey={[field.fieldKey, 'value']}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Tooltip"
                    tooltip="Tooltip for the field"
                    name={[field.name, 'tooltip']}
                    fieldKey={[field.fieldKey, 'tooltip']}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Help"
                    tooltip="Help text for the field"
                    name={[field.name, 'help']}
                    fieldKey={[field.fieldKey, 'help']}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Required"
                    name={[field.name, 'required']}
                    fieldKey={[field.fieldKey, 'required']}
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item label="Remove Field">
                    <Popconfirm title="Remove field?" onConfirm={() => remove(field.name)}>
                        <Button danger icon={<DeleteOutlined />}  ></Button>
                    </Popconfirm>
                </Form.Item>
            </>
        )
    };

    return (
        <>
            <Form.List name={["target", "fields"]}>
                {(fields, { add, remove }) => {
                    const items = fields.map(m => { return { key: m.key, label: fieldNames[m.name] || "New Field *" } });

                    const onAdd = () => {
                        const item = {
                            key: fields.length + 1
                        }
                        add(item);
                    }

                    return (<>
                        <Typography.Text strong>Fields</Typography.Text>
                        <Typography.Paragraph>Fields the user can enter when submitting the form. These fields will be passed as variables to the target.</Typography.Paragraph>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Button icon={<FileAddFilled />} onClick={onAdd}>Add Field</Button>
                            <Menu items={items} mode="horizontal" onClick={({ key }) => setTab(key)} activeKey={tab} />
                            {fields.map(x => <Card key={`${x.key}Content`} hidden={x.key.toString() !== tab}>{formField(x, remove)}</Card>)}
                        </Space>
                    </>)
                }}
            </Form.List>
        </>
    )
}
