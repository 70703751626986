
import React from "react";
import { Route, Routes } from "react-router-dom";

const Hotkeys = React.lazy(() => import("../hotkeys/hotkeys"));
const FileAssociations = React.lazy(() => import("../fileAssociations/fileAssociations"));
const ProtocolHandlers = React.lazy(() => import("../protocolHandler/protocolHandlers"));

export default function DesktopRoute() {
    return (
        <Routes>
            <Route path="fileAssociations" element={<FileAssociations />} />
            <Route path="hotkeys" element={<Hotkeys />} />
            <Route path="protocolHandlers" element={<ProtocolHandlers />} />
        </Routes>
    );
}
