import React from "react";
import { Button, Result } from "antd";
import { toRelativeUrl } from "components/utils/utils";
import { useQuery } from "react-query3";

export default function AuthFailure() {
    const { data } = useQuery<any>("/alive");

    return (
        <Result
            title="There was an error logging in due to a misconfiguration."
            subTitle={data?.loginErrorMessage}
            status={"error"}
            extra={[
                <Button type="primary" onClick={() => window.location.href = toRelativeUrl("/login")}>
                    Login using Forms Authentication
                </Button>
            ]
            }
        />
    );
}
