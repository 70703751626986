import { PlusOutlined, ToolOutlined } from "@ant-design/icons";
import { Button, Space, Modal, Typography, Menu, Card } from "antd";
import React, { ReactNode } from "react";

export default function Toolbox(props) {
    const [activeTab, setActiveTab] = React.useState("charts");

    const renderItem = (item): ReactNode => {
        return (
            <Button key={item} icon={<PlusOutlined />} onClick={() => props.addComponent(item)}>{item}</Button>
        )
    }


    const menuItems = [
        {
            key: 'charts',
            label: 'Charts'
        },
        {
            key: 'display',
            label: 'Data Display'
        },
        {
            key: 'input',
            label: 'Input'
        },
        {
            key: 'typography',
            label: 'Typography'
        },
    ]

    return (
        <Modal
            title={<><ToolOutlined />   Toolbox</>}
            onOk={props.onClose}
            onCancel={props.onClose}
            visible={props.visible}
            width={"90vw"}
        >
            <Menu mode="horizontal" items={menuItems} defaultSelectedKeys={['console']} onClick={({ key }) => setActiveTab(key)} />
            <Card>
                {activeTab === "charts" && <Space direction="vertical">
                    <Typography>Display charts based on APIs and Script output.</Typography>
                    <Space>
                        {["Bar", "Line", "Liquid", "Pie"].map(renderItem)}
                    </Space>
                </Space>}
                {activeTab === "display" && <Space direction="vertical">
                    <Typography>Static and dynamic data displays.</Typography>
                    <Space>
                        {["Alert", "Card", "IFrame", "Image", "Statistic", "Table", "Tag"].map(renderItem)}
                    </Space>
                </Space>}
                {activeTab === "input" && <Space direction="vertical">
                    <Typography>Execute APIs and scripts based on user input.</Typography>
                    <Space>
                        {["Button", "Form"].map(renderItem)}
                    </Space>
                </Space>}
                {activeTab === "typography" &&
                    <Space direction="vertical">
                        <Typography>Static text to display on the page.</Typography>
                        <Space>
                            {["Paragraph", "Text", "Title"].map(renderItem)}
                        </Space>
                    </Space>}
            </Card>

        </Modal>
    )
}