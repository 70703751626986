import { Table, Button, Tooltip, Space, Input, Typography } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import Papa from "papaparse";
import { ExportOutlined, ReloadOutlined } from '@ant-design/icons';
import PSUButton from './Button';
import useDataSource from '../DataSource';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { matchSorter } from "match-sorter";
import * as Icons from '@ant-design/icons';
import React from 'react';
import TableTitle from 'components/ui/TableTitle';

export const PSUTableProps: Array<IComponentProperty> = [
    { "name": "id", "type": ComponentPropertyType.String, "required": false, "description": "The ID for this table", displayName: "Id" },
    { "name": "title", "type": ComponentPropertyType.String, "required": true, "description": "The title of this table.", displayName: "Title" },
    { "name": "icon", "type": ComponentPropertyType.Icon, "required": false, "description": "The icon to show next to the title.", displayName: "Icon" },
    { "name": "allowExport", "type": ComponentPropertyType.Boolean, "required": false, "description": "Allow exporting from this table.", displayName: "Allow Export" },
    { "name": "allowManualRefresh", "type": ComponentPropertyType.Boolean, "required": false, "description": "Allow manual refresh of table data.", displayName: "Allow Manual Refresh" },
    { "name": "allowSearch", "type": ComponentPropertyType.Boolean, "required": false, "description": "Allow searching of the data.", displayName: "Allow Search" },
    { "name": "showScrollBars", "type": ComponentPropertyType.Boolean, "required": false, "description": "Show scrollbars.", displayName: "Show Scrollbars" },
    { "name": "size", "type": ComponentPropertyType.Select, "required": false, "description": "The size of the table.", displayName: "Size", options: [{ value: "small", text: "Small" }, { value: "middle", text: "Middle" }, { value: "default", text: "Default" }] },
    { "name": "refreshInterval", "type": ComponentPropertyType.Number, "required": false, "description": "The number of seconds between refreshes of the table data.", displayName: "Refresh Interval" },
    { "name": "pageSize", "type": ComponentPropertyType.Number, "required": false, "description": "The default number of rows to display.", displayName: "Default Page Size" },
    { "name": "dataSource", "type": ComponentPropertyType.DataSource, "required": true, "description": <>The <Link to="/admin/automation/scripts" target="_blank">script</Link> or <Link to="/admin/apis/endpoints" target="_blank">API</Link> data source for this table.</>, displayName: "Data Source", category: "Data Source" },
    { "name": "columns", "type": ComponentPropertyType.TableColumns, "required": false, "description": "Columns to display in this table. If you do not specify columns, all properties will be displayed.", displayName: "Columns", category: "Columns" },
]

export const NewPSUTable = () => {
    return {
        "title": "Table",
        "type": "Table",
        "id": uuidv4(),
        "pageSize": 10,
        "$type": 'PowerShellUniversal.Components.Table, PowerShellUniversal'
    }
}

export function getExportFileBlob({ columns, data, fileType }) {
    if (fileType === "csv") {
        const exportData = data.map(item => {
            const row = {}
            columns.forEach(col => {
                row[col.title] = item[col.dataIndex]
            })
            return row;
        })

        const csvString = Papa.unparse(exportData);
        return new Blob([csvString], { type: "text/csv" });
    }

    return false;
}

export default function PSUTable(props) {
    const { refetch, data, isLoading } = useDataSource(props)
    const [searchValue, setSearchValue] = useState("");

    var columns = props.columns;

    const colSort = (col, col2, type, propertyName) => {
        if (type === 'date') {
            const val1 = Date.parse(col[propertyName])
            const val2 = Date.parse(col2[propertyName])

            if (val1 === val2) return 0;
            if (val1 < val2) return -1;
            if (val1 > val2) return 1;
        } else if (type === 'float') {
            const val1 = Number.parseFloat(col[propertyName])
            const val2 = Number.parseFloat(col2[propertyName])

            if (val1 === val2) return 0;
            if (val1 < val2) return -1;
            if (val1 > val2) return 1;
        } else if (type === 'int') {
            const val1 = Number.parseInt(col[propertyName])
            const val2 = Number.parseInt(col2[propertyName])

            if (val1 === val2) return 0;
            if (val1 < val2) return -1;
            if (val1 > val2) return 1;
        } else if (type === 'basic') {
            return ('' + col[propertyName]).localeCompare(col2[propertyName])
        }
    }

    if (columns && columns.length !== 0) {
        columns = columns.map(x => {
            return {
                title: x.name,
                dataIndex: x.propertyName,
                key: x.name,
                render: (text, record) => {
                    if (x.type === 'button') {
                        return <PSUButton {...x.button} inputObject={record} onSuccess={() => refetch()} />
                    }
                    if (x.type === 'date') {
                        return dayjs(record[x.propertyName]).format(x.dateTimeFormat);
                    }
                    if (x.type === 'float') {
                        return Number.parseFloat(record[x.propertyName]);
                    }
                    if (x.type === 'int') {
                        return Number.parseInt(record[x.propertyName]);
                    }

                    return String(record[x.propertyName]);
                },
                sorter: x.sortable ? (a, b) => colSort(a, b, x.type, x.propertyName) : null,
            }
        })
    }
    else if (data && data.length !== 0) {
        columns = Object.keys(data[0])?.map(x => {
            return {
                title: x,
                dataIndex: x,
                key: x,
                render: (text, record) => {
                    return String(record[x]);
                }
            }
        })
    }

    const keysToSort = useMemo(
        () =>
            columns?.map(
                (col) => (col?.dataIndex && `${col?.dataIndex}`) || `${col?.key}`
            ),
        [columns]
    );

    function query(search: any) {
        let results = matchSorter(data, search, {
            keys: keysToSort,
        });
        return results;
    }

    const onExport = (key) => {
        const fileName = `${props.title}.${key.key}`;
        const fileType = key.key;
        const blob = getExportFileBlob({ columns, data, fileType });
        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const buttons = []

    if (props.allowSearch) {
        buttons.push(
            <Input.Search
                allowClear
                placeholder={`Search ${data?.length} items...`}
                bordered={false}
                onSearch={(value) => setSearchValue(value)}
                enterButton
            />
        )
    }


    if (props.allowExport) {
        buttons.push(
            <Tooltip title="Export">
                <Button disabled={isLoading} icon={<ExportOutlined />} onClick={() => onExport({ key: "csv" })}></Button>
            </Tooltip>
        )
    }

    if (props.allowManualRefresh) {
        buttons.push(
            <Tooltip title="Refresh">
                <Button disabled={isLoading} icon={<ReloadOutlined />} onClick={() => refetch()}></Button>
            </Tooltip>
        )
    }


    return (
        <Table
            loading={isLoading}
            dataSource={searchValue ? query(searchValue) : data}
            columns={columns}
            size={props.size}
            style={{ overflow: 'auto' }}
            pagination={
                {
                    showSizeChanger: true,
                    defaultPageSize: props.pageSize
                }
            }
            title={() => <TableTitle
                style={{
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >

                <Typography.Title level={3}>
                    <Space>
                        {props.icon && React.createElement(Icons[props.icon])}
                        {props.title}
                    </Space>
                </Typography.Title>
                <Space>
                    {buttons}
                </Space>
            </TableTitle>}
        />
    )

}