import React from "react";
import { Button, Result } from "antd";
import { toRelativeUrl } from "components/utils/utils";

export default function LoggedOutPage() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let returnUrl = params.returnUrl;

    if (returnUrl && returnUrl.startsWith("/"))
        returnUrl = toRelativeUrl(returnUrl);
    else
        returnUrl = toRelativeUrl("/admin");

    return (
        <Result
            title="Logged Out"
            subTitle="You have been logged out."
            extra={[
                <Button type="primary" onClick={() => window.location.href = returnUrl}>
                    Login
                </Button>
            ]
            }
        />
    );
}
