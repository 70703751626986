import { CloseCircleOutlined, ControlOutlined, EditOutlined, LogoutOutlined, SaveOutlined, SettingOutlined, ToolOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu, message, PageHeader, Popconfirm, Skeleton, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import PSUComponent from "./Component";
import { DesignSurface } from './DesignSurface';
import { Page as PSUPage, Variable } from 'types';
import Scrollbars from "rc-scrollbars";
import { useMutation } from "components/utils/queryClient";
import useNormalizeData from "components/utils/normalizedRecord";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { SiderTheme } from "antd/es/layout/Sider";
import { useNavigate, useParams } from "react-router";
import PageProperties from './PageProperties';
import * as Icons from '@ant-design/icons';
import { WidthProvider, Responsive } from "react-grid-layout";
import ThemeToggle from "components/ui/theme/themeToggle";
import Logo from "components/ui/Logo";
import React from "react";
import RoleGuard from "components/standalone/role-guard";
import { Page as PageContext } from "components/pages/Page";
import { VariableContext, PageVariable, DataSourceComponent } from './VariableContext';
import VariableDrawer from './VariableDrawer';
import { useQuery, useQueryClient } from "react-query3";
import { loadDataSource } from "./DataSource";
import { ComponentPropertyType, IComponentProperty } from "./ComponentProperty";
import Draggable from 'react-draggable';

require('react-grid-layout/css/styles.css');
require('react-resizable/css/styles.css');

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const { Header, Sider, Content } = Layout;


export interface PageProps {
  page: PSUPage,
  pages: Array<PSUPage>,
  logout: () => void,
  username?: string
  licensed: boolean;
  mode?: string;
  builtInRole: boolean;
}



export default function Page(props: PageProps) {
  const { page: propPage, pages, licensed } = props;

  const builtInVariables = [
    {
      name: '$username',
      value: props.username,
      session: false,
      context: 'global'
    },
    {
      name: '$pagename',
      value: props.page.name,
      session: false,
      context: 'global'
    }
  ]

  const replaceVariables = (context, props, properties: Array<IComponentProperty>) => {
    if (!licensed) return props;
    const newProps = { ...props };

    properties.forEach(property => {
      context.variables.forEach(variable => {
        switch (property.type) {
          case ComponentPropertyType.String:
            newProps[property.name] = newProps[property.name]?.replace(variable.name, variable.value);
            break;
          case ComponentPropertyType.DataSource:
            newProps['dataSource'] = newProps['dataSource']?.replace(variable.name, variable.value);
            break;
        }
      });
    });

    return newProps;
  }

  const navigate = useNavigate();
  const { currentTheme } = useThemeSwitcher()
  const [editing, setEditing] = useState(false);
  const [toolboxVisible, setToolboxVisible] = useState(false);
  const [variablesVisible, setVariablesVisible] = useState(false);
  const [pagePropertiesVisible, setPagePropertiesVisible] = useState(false);
  const [sideCollapsed, setSideCollapsed] = useState(false);
  const { mutateAsync } = useMutation();
  const normalizedRecord = useNormalizeData();
  const dataSourceComponents = useRef<Array<DataSourceComponent>>([]);
  const queryClient = useQueryClient();

  const [page, setPage] = useState(propPage);
  const [components, setComponents] = useState(propPage.components);
  const [layout, setLayout] = useState(propPage.layout || { lg: [] });
  const [loadingDataSource, setLoadingDataSource] = useState(true)

  const params = useParams();
  const paramVariables = Object.keys(params).map(key => ({
    name: `$${key}`,
    value: params[key],
    session: false,
    context: 'route'
  }));


  const [variables, setVariables] = useState<Array<PageVariable>>([
    ...builtInVariables,
    ...paramVariables
  ]);
  const [credentials, setCredentials] = useState<Array<Variable>>([]);
  const { isLoading: variablesLoading } = useQuery<Array<Variable>>('/variable', {
    enabled: !!props.username && props.builtInRole,
    onSuccess: (data) => {
      setCredentials(data.filter(m => m.type === 'PSCredential'));
      setVariables(
        [
          ...variables,
          ...data.filter(m => !m.vault).map(v => ({
            name: '$' + v.name,
            value: v.value,
            context: 'platform',
            session: false
          }))
        ]
      );
    }
  });

  // This is horrible and I don't know why I can't use react-query here. 
  // I also don't know why I can't specify dependencies in the query.
  useEffect(() => {
    let dataSource = propPage.dataSource;
    if (propPage.dataSourceType === 'api') {
      Object.keys(params).forEach(key => {
        dataSource = dataSource.replace(`$${key}`, params[key])
      });
    }


    loadDataSource(dataSource, propPage.dataSourceType).then(data => {
      if (data === null) {
        setLoadingDataSource(false);
        return;
      };
      if (Array.isArray(data) && data.length === 0) {
        setLoadingDataSource(false);
        return;
      }
      setVariables(
        [
          ...variables,
          ...Object.keys(data[0]).filter(v => v !== null).map(v => ({
            name: '$' + v,
            value: data[0][v],
            context: 'pageDataSource',
            session: false
          }))
        ]

      );
      setLoadingDataSource(false);
    }).catch(e => {
      message.error("Failed to load the data source for this page. Make sure that the API or script is returning data.");
      setLoadingDataSource(false);
    })
    return () => { }
    // eslint-disable-next-line
  }, []);

  const registerComponent = (id: string, url: string) => {
    dataSourceComponents.current.push({
      id,
      url
    });
  }

  const unregisterComponent = (id: string) => {
    dataSourceComponents.current = dataSourceComponents.current.filter(m => m.id !== id);
  }

  const getComponents = () => dataSourceComponents.current.map(m => m.id);

  const refreshComponents = (ids: Array<string>) => {
    if (!ids) return;
    const urls = dataSourceComponents.current.filter(m => ids.indexOf(m.id) !== -1).map(m => m.url);
    urls.forEach(x => {
      queryClient.invalidateQueries(x)
    })
  }

  const setVariable = (variable: PageVariable) => {
    variable.name = variable.name.startsWith('$') ? variable.name : "$" + variable.name;
    var newVariables = variables.slice().filter(m => m.name !== variable.name);
    newVariables.push(variable);
    setVariables(newVariables);
  }

  if (variablesLoading || loadingDataSource) { // || isLoading) {
    return <Skeleton />
  }

  const onCancel = () => {
    setEditing(false);
    setPage(propPage);
    setComponents(propPage.components);
    setLayout(propPage.layout || { lg: [] });
  }

  const onSave = async () => {
    await mutateAsync({
      key: `/page/${page.id}`,
      action: "update",
      ...normalizedRecord({ ...page, components, layout })
    });
    window.location.reload();
    setEditing(false);
  }

  const PageLayout = ({ page }) => {

    const content = (
      editing ?
        <DesignSurface
          components={components}
          setComponents={setComponents}
          layout={layout}
          setLayout={setLayout}
          toolboxVisible={toolboxVisible}
          setToolboxVisible={setToolboxVisible} /> :
        <ResponsiveReactGridLayout
          layouts={layout}
          isDraggable={false}
          isResizable={false}
          measureBeforeMount={true}
          useCSSTransforms={false}
          rowHeight={10}
          cols={{ lg: 50, md: 10, sm: 6, xs: 4, xxs: 2 }}>
          {page.components.map(component =>
            <div key={component.id} id={component.id} style={{ overflow: component.type === "table" ? 'auto' : 'inherit' }}>
              <PSUComponent {...component} />
            </div>)
          }
        </ResponsiveReactGridLayout>
    )

    return (
      <PageContext resource={{}}>
        {page.hideTitle && content}
        {!page.hideTitle && (
          <PageHeader
            title={!page.hideTitle && page.name}
            subTitle={!page.hideTitle && page.description}>
            {content}
          </PageHeader>
        )}
      </PageContext>
    )
  }

  let pageButtons;
  if (editing) {
    pageButtons = <>
      <Button onClick={onSave} icon={<SaveOutlined />} style={{ width: '120px' }} type="primary">Save</Button>
      <Popconfirm title="Are you sure?" onConfirm={onCancel}><Button icon={<CloseCircleOutlined />} style={{ width: '120px' }} danger type="primary">Cancel</Button></Popconfirm>
      <Button onClick={() => setPagePropertiesVisible(true)} icon={<SettingOutlined />} style={{ width: '120px' }}>Properties</Button>
      <Button onClick={() => setToolboxVisible(true)} icon={<ToolOutlined />} style={{ width: '120px' }}>Toolbox</Button>
      <Button onClick={() => setVariablesVisible(true)} icon={<ControlOutlined />} style={{ width: '120px' }}>Variables</Button>
    </>
  } else {
    pageButtons = <>
      <RoleGuard requiredRoles={["Administrator"]}>
        <Button onClick={() => setEditing(true)} icon={<EditOutlined />} style={{ width: '120px' }} type="primary">Edit</Button>
      </RoleGuard>
    </>
  }

  const logout = !page.hideHeader && (page.showLogout ? (
    <Dropdown
      placement="bottomCenter"
      //@ts-ignore
      destroyPopupOnHide={true}
      trigger={["click"]}
      overlay={() => (
        <Menu onClick={() => props.logout()}>
          <Menu.Item key="signout" icon={<LogoutOutlined />}>
            Signout
          </Menu.Item>
        </Menu>
      )}
    >
      <Button type="text" icon={<UserOutlined />}>{props.username}</Button>
    </Dropdown>
  ) : (
    <Space>
      <UserOutlined />
      {props.username}
    </Space>
  ));

  function onClick({ key }) {
    if (editing) {
      message.warning("Can't navigate while editing");
      return;
    }

    navigate(key);
  }

  const menu = () => {
    const getUrl = (navPage: PSUPage) => {
      let url = navPage.url && navPage.url !== '' ? navPage.url : navPage.name;
      if (url.startsWith("/")) {
        url = url.substring(1, url.length);
      }
      return url;
    }

    const renderPageMenuItem = (navPage: PSUPage) => {
      const url = getUrl(navPage);
      return <Menu.Item key={"/" + url} icon={navPage.icon && React.createElement(Icons[navPage.icon])}> {navPage.name}</Menu.Item>
    }

    const groups = pages.filter(m => m.showInNavigation).filter(m => m.group).map(m => m.group).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a.localeCompare(b));
    const ungroupedPages = pages.filter(m => m.showInNavigation && !m.group).sort((a, b) => a.name.localeCompare(b.name));

    let menuItems = [];
    ungroupedPages.forEach(page => {
      menuItems.push(renderPageMenuItem(page));
    });

    groups.forEach(group => {
      menuItems.push(<Menu.SubMenu key={group} title={group}>{pages.filter(m => m.group === group).map(renderPageMenuItem)}</Menu.SubMenu>)
    });

    return menuItems;
  }

  return (
    <VariableContext.Provider value={{
      credentials,
      variables,
      setVariable,
      replaceVariables,
      refreshComponents,
      registerComponent,
      unregisterComponent,
      getComponents
    }}>
      <Layout style={{ height: "100vh" }}>
        {!page.hideHeader && <Header style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#ffffff",
          backgroundColor: currentTheme === 'light' ? '#fff' : '#1f1f1f',
          padding: "0px 8px"
        }}>
          <Logo mode={props?.mode} />
          <div style={{ width: 28, margin: "0 18px", flex: "0" }}></div>

          <Space>
            <ThemeToggle
              checkedChildren="Dark"
              unCheckedChildren="Light"
              size="small"
            />
            {logout}
          </Space>
        </Header>}
        <Layout hasSider={page.showNavigation}>
          {page.showNavigation ? (
            <Sider collapsible collapsed={sideCollapsed} onCollapse={setSideCollapsed} theme={currentTheme as SiderTheme}>
              <Menu theme={currentTheme as SiderTheme} defaultSelectedKeys={['nav' + page.id]} onClick={onClick} mode="inline">
                {menu()}
              </Menu>
            </Sider>) : <></>}
          <Content>
            <Scrollbars hideTracksWhenNotNeeded autoHide disableDefaultStyles>
              <PageLayout page={page} />
              <PageProperties visible={pagePropertiesVisible} setVisible={setPagePropertiesVisible} page={page} setPage={setPage} />
            </Scrollbars>
            <Draggable>
              <div style={{
                position: "fixed",
                top: page.hideHeader ? '10px' : '75px',
                right: '32px',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'right',
                background: currentTheme === 'light' ? 'white' : '#1f1f1f',
                padding: '10px',
                zIndex: 2
              }}>
                <div style={{
                  height: '20px',
                  background: '#a9a9a9',
                  marginBottom: '5px',
                  cursor: "grab",
                  opacity: '0.95',
                  backgroundImage: 'radial-gradient(#444cf7 1px, #a9a9a9 1px)',
                  backgroundSize: '5px 5px'
                }}></div>
                <Space direction="vertical">
                  {pageButtons}
                </Space>
              </div>
            </Draggable>

          </Content>
          <VariableDrawer visible={variablesVisible} setVisible={setVariablesVisible} page={page} licensed={licensed} />
        </Layout>
      </Layout>
    </VariableContext.Provider>
  )
}