import { ComputerGroup } from "../../../types";
import { CustomSchema } from "./types";

export const computerGroupSchema: CustomSchema<ComputerGroup>[] = [
    {
        name: "name",
        label: "Name",
        component: "input",
        required: true,
        uniqe: true,
        tooltip: "The name of this computer group.",
    },
    {
        name: "description",
        label: "Description",
        component: "input",
        tooltip: "The description of this computer group.",
    },
    {
        name: "tags",
        label: "Tags",
        component: "selectAsTags",
        tooltip: "Tags used to select computers in this group.",
    },

]