import React from "react";
import { AccessControlTypes, PSUTypes } from "types";
import { Button, ButtonProps, Popconfirm, Tooltip } from "antd";
import { useActionButton } from "components/utils/hooks/useActionButton";
import RoleGuard from "../standalone/role-guard";
import queryClient, { useMutation } from "components/utils/queryClient";

type DeleteButtonProps = {
  resource: PSUTypes;
  refetch?: Array<string>;
  allowedWithOneWayGitSync: boolean;
  ignoreRoleGuard?: boolean;
  noDemo?: boolean;
  onSuccess?: any;
  tooltip?: string;
} & Omit<ButtonProps, "resource">;

export default function DeleteButton({
  resource,
  refetch,
  allowedWithOneWayGitSync,
  ignoreRoleGuard,
  noDemo,
  onSuccess,
  tooltip,
  ...props
}: DeleteButtonProps) {
  const { getButtonProps } = useActionButton();
  const { mutateAsync, isLoading } = useMutation();

  var rec = resource as any;
  if (rec?.readOnly) {
    return <></>
  }

  return (
    <RoleGuard
      requiredRoles={["Administrator", "Operator"]}
      requiredAccessControls={AccessControlTypes.Delete}
      accessControls={resource?.accessControls}
      allowedWithOneWayGitSync={allowedWithOneWayGitSync}
      noDemo={noDemo}
      ignore={ignoreRoleGuard}
    >
      <Popconfirm
        disabled={resource?.readOnly}
        title="Are you sure?"
        onConfirm={() =>
          mutateAsync(
            {
              key: resource?.resourceInfo?.self,
              action: "delete",
            },
            {
              onSuccess: () => {
                if (resource?.resourceInfo?.schemaName === "license") {
                  queryClient.resetQueries("/license/feature");
                  queryClient.resetQueries("/license", {
                    active: true,
                    inactive: true,
                    stale: true,
                  });
                }
                if (resource?.resourceInfo?.schemaName === "dashboardPage") {
                  queryClient.refetchQueries("/dashboard/page");
                }
                if (resource?.resourceInfo?.schemaName === "role") {
                  queryClient.refetchQueries("/role", {
                    active: true,
                    inactive: true,
                    stale: true,
                  });
                  queryClient.refetchQueries(
                    `${resource?.resourceInfo?.parent}/identity`,
                    { active: true, inactive: true, stale: true }
                  );
                }

                queryClient.refetchQueries(resource?.resourceInfo?.parent);

                if (refetch) {
                  refetch.forEach(x => queryClient.refetchQueries(x));
                }

                if (onSuccess) {
                  onSuccess();
                }
              },
            }
          )
        }
      >
        <Tooltip title={tooltip || "Delete"}>
          <Button
            {...props}
            {...getButtonProps({
              action: "delete",
            })}
            loading={isLoading}
            disabled={resource?.readOnly}
          />
        </Tooltip>

      </Popconfirm>
    </RoleGuard>
  );
}
