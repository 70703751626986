import { Translation } from "../../../types";
import { CustomSchema } from "./types";

export const translationSchema: CustomSchema<Translation>[] = [
    {
        name: "languageId",
        label: "Language ID",
        component: "input",
        required: true,
        uniqe: true,
    },
    {
        name: "name",
        label: "Name",
        component: "input",
    },
];
