import React from "react";
import { Menu, Layout } from "antd";
import { useNavigate } from "react-router";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { SiderTheme } from "antd/es/layout/Sider";
import * as Icons from '@ant-design/icons';
import {
  FileTextOutlined,
  CodeOutlined,
  HistoryOutlined,
  ClockCircleOutlined,
  ControlOutlined,
  SettingOutlined,
  ApiOutlined,
  FileProtectOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  FolderOutlined,
  GlobalOutlined,
  FunnelPlotOutlined,
  RocketOutlined,
  TagsOutlined,
  SafetyOutlined,
  TeamOutlined,
  IdcardOutlined,
  BarcodeOutlined,
  BuildOutlined,
  DesktopOutlined,
  BlockOutlined,
  LoginOutlined,
  HomeOutlined,
  PullRequestOutlined,
  ImportOutlined,
  BugOutlined,
  ThunderboltOutlined,
  AlertOutlined,
  FileDoneOutlined,
  LayoutOutlined,
  NodeIndexOutlined,
  SafetyCertificateOutlined,
  CommentOutlined,
  BookOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ContainerOutlined,
  FileOutlined
} from "@ant-design/icons";
import useAppStore from "components/context/app/Hooks";
import Scrollbars from "rc-scrollbars";
import { useQuery } from "react-query3";
import { Page, Features } from "types";
import { toRelativeUrl } from "components/utils/utils";
import { useTranslation } from "react-i18next";

export default function UAMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: pages, isLoading } = useQuery<Page[]>("/page/view");
  const { settings, userData } = useAppStore();
  const { currentTheme } = useThemeSwitcher();
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);

  function onClick({ key }) {
    if (key === "help") {
      window.open("https://docs.powershelluniversal.com");
      return;
    }

    if (key.startsWith("udpage")) navigate(toRelativeUrl(key.substr(4)));
    else navigate(toRelativeUrl("/admin/" + key));
  }

  const collapsed = sessionStorage.getItem('nav-collapsed') === 'true';

  const onCollapse = (collapsed) => {
    sessionStorage.setItem('nav-collapsed', collapsed);
  }


  const pageMenu = () => {
    if (isLoading || !pages) {
      return <React.Fragment />
    }
    const getUrl = (navPage: Page) => {
      let url = navPage.url && navPage.url !== '' ? navPage.url : navPage.name;
      if (url.startsWith("/")) {
        url = url.substring(1, url.length);
      }
      return url;
    }

    const renderPageMenuItem = (navPage: Page) => {
      const url = getUrl(navPage);
      return <Menu.Item key={"udpage/" + url} icon={navPage.icon && React.createElement(Icons[navPage.icon])}> {navPage.name}</Menu.Item>
    }

    const groups = pages.filter(m => m.showInNavigation).filter(m => m.group).map(m => m.group).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a.localeCompare(b));
    const ungroupedPages = pages.filter(m => m.showInNavigation && !m.group).sort((a, b) => a.name.localeCompare(b.name));

    let menuItems = [];
    ungroupedPages.forEach(page => {
      menuItems.push(renderPageMenuItem(page));
    });

    groups.forEach(group => {
      menuItems.push(<Menu.SubMenu key={group} title={group}>{pages.filter(m => m.group === group).map(renderPageMenuItem)}</Menu.SubMenu>)
    });

    return menuItems;
  }

  const shouldDisplay = (hide: boolean, builtIn: boolean, feature: Features) => {
    if (hide) return false;
    if ((settings?.disabledFeatures & feature) === feature) return false;
    return builtIn;
  }

  return (
    <Layout.Sider
      collapsible
      defaultCollapsed={collapsed}
      onCollapse={onCollapse}
      theme={currentTheme as SiderTheme}
    >
      <Scrollbars autoHide hideTracksWhenNotNeeded>
        <Menu
          mode={collapsed ? "vertical" : "inline"}
          theme={currentTheme as SiderTheme}
          key={currentTheme}
          style={{ height: "100%", borderRight: 0 }}
          onClick={onClick}
          inlineCollapsed={collapsed}
          subMenuCloseDelay={.5}
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
        >
          {!settings?.hideHomePage && userData?.builtInRole && (
            <Menu.Item key="home" icon={<HomeOutlined />}>
              {t('menu.home')}
            </Menu.Item>
          )}
          {shouldDisplay(settings?.hideApi, userData?.builtInRole, Features.Api) && (
            <Menu.SubMenu icon={<ApiOutlined />} key="api" title={t('menu.apis')} popupClassName={openKeys.find(x => x === "api") ? null : "hidden"}>
              <Menu.Item key="apis/endpoints" icon={<ApiOutlined />}>
                {t('menu.endpoints')}
              </Menu.Item>
              <Menu.Item key="apis/endpoints/docs" icon={<BookOutlined />}>
                {t('menu.endpointDocumentation')}
              </Menu.Item>
              <Menu.Item key="apis/eventhubs" icon={<DeploymentUnitOutlined />}>
                {t('menu.eventHubs')}
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {shouldDisplay(settings?.hideAutomation, true, Features.Scripts) && (
            <Menu.SubMenu
              icon={<HistoryOutlined />}
              key="automation"
              title={t('menu.automation')}
              popupClassName={openKeys.find(x => x === "automation") ? null : "hidden"}
            >
              <Menu.Item key="automation/scripts" icon={<FileTextOutlined />}>
                {t('menu.scripts')}
              </Menu.Item>
              <Menu.Item key="automation/jobs" icon={<ProfileOutlined />}>
                {t('menu.jobs')}
              </Menu.Item>
              <Menu.Item
                hidden={!userData?.builtInRole}
                key="automation/schedules"
                icon={<ClockCircleOutlined />}
              >
                {t('menu.schedules')}
              </Menu.Item>
              <Menu.Item
                key="automation/systemEvents"
                icon={<AlertOutlined />}
                hidden={!userData?.builtInRole}
              >
                System Events
              </Menu.Item>
              <Menu.Item
                hidden={!userData?.builtInRole}
                key="automation/terminals"
                icon={<CodeOutlined />}
              >
                {t('menu.terminals')}
              </Menu.Item>
              <Menu.Item
                hidden={!userData?.builtInRole}
                key="automation/triggers"
                icon={<RocketOutlined />}
              >
                {t('menu.triggers')}
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {userData?.mode === 'Desktop' && (
            <Menu.SubMenu
              icon={<DesktopOutlined />}
              key="desktop"
              title="Desktop"
              popupClassName={openKeys.find(x => x === "desktop") ? null : "hidden"}
            >
              <Menu.Item key="desktop/fileAssociations" icon={<FileDoneOutlined />}>
                {t('menu.fileAssociations')}
              </Menu.Item>
              <Menu.Item key="desktop/hotkeys" icon={<ThunderboltOutlined />}>
                Hotkeys
              </Menu.Item>
              <Menu.Item key="desktop/protocolHandlers" icon={<NodeIndexOutlined />}>
                Protocol Handlers
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {!settings?.hideDashboard && !userData?.builtInRole && pageMenu()}
          {!settings?.hideDashboard && userData?.builtInRole && (
            <Menu.SubMenu
              icon={<LayoutOutlined />}
              key="interfaces"
              title={t('menu.userInterfaces')}
              popupClassName={openKeys.find(x => x === "uis") ? null : "hidden"}
            >
              <Menu.Item key="apps" icon={<AppstoreOutlined />}>
                {t('menu.apps')}
              </Menu.Item>
              <Menu.Item key="pages" icon={<FileOutlined />}>
                {t('menu.pages')}
              </Menu.Item>
              {/* <Menu.Item key="apps/components" icon={<GroupOutlined />}>
                {t('menu.components')}
              </Menu.Item>
              <Menu.Item key="apps/templates" icon={<AppstoreOutlined />}>
                {t('menu.templates')}
              </Menu.Item>
              <Menu.Item key="apps/themes" icon={<BgColorsOutlined />}>
                {t('menu.themes')}
              </Menu.Item> */}
            </Menu.SubMenu>
          )}
          {userData?.builtInRole && (
            <Menu.SubMenu
              icon={<BlockOutlined />}
              key="platform"
              title={t('menu.platform')}
              popupClassName={openKeys.find(x => x === "platform") ? null : "hidden"}
            >
              <Menu.Item key="platform/debugging" icon={<BugOutlined />}>
                {t('menu.debugging')}
              </Menu.Item>
              {shouldDisplay(false, true, Features.PublishedFolders) &&
                <Menu.Item key="platform/folders" icon={<FolderOutlined />}>
                  {t('menu.publishedFolders')}
                </Menu.Item>
              }
              <Menu.Item key="platform/log" icon={<ContainerOutlined />}>
                {t('menu.logging')}
              </Menu.Item>
              <Menu.Item key="platform/modules" icon={<ImportOutlined />}>
                {t('menu.modules')}
              </Menu.Item>
              {shouldDisplay(false, true, Features.RateLimiting) &&
                <Menu.Item key="platform/ratelimits" icon={<FunnelPlotOutlined />}>
                  {t('menu.rateLimiting')}
                </Menu.Item>
              }
              <Menu.Item
                key="platform/computers"
                icon={<DesktopOutlined />}>
                Computers
              </Menu.Item>
              <Menu.Item key="platform/translations" icon={<CommentOutlined />}>
                {t('menu.translations')}
              </Menu.Item>
              <Menu.Item
                hidden={!userData?.builtInRole}
                key="platform/variables"
                icon={<ControlOutlined />}
              >
                {t('menu.variables')}
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {userData?.roles?.includes("Administrator") && (
            <Menu.SubMenu
              icon={<SettingOutlined />}
              key="settingsheader"
              title={t('menu.settings')}
              popupClassName={openKeys.find(x => x === "settingsheader") ? null : "hidden"}
            >
              <Menu.Item key="settings" icon={<SettingOutlined />}>
                {t('menu.general')}
              </Menu.Item>
              <Menu.Item key="settings/configurations" icon={<BuildOutlined />}>
                {t('menu.configurations')}
              </Menu.Item>
              <Menu.Item key="settings/environments" icon={<GlobalOutlined />}>
                {t('menu.environments')}
              </Menu.Item>
              <Menu.Item key="settings/git" icon={<PullRequestOutlined />}>
                Git
              </Menu.Item>
              <Menu.Item key="settings/license" icon={<FileProtectOutlined />}>
                {t('menu.license')}
              </Menu.Item>
              {userData?.mode !== "Desktop" && (
                <Menu.Item key="settings/loginPage" icon={<LoginOutlined />}>
                  {t('menu.loginPage')}
                </Menu.Item>
              )}

              <Menu.Item key="settings/tags" icon={<TagsOutlined />}>
                {t('menu.tags')}
              </Menu.Item>
              <Menu.Item key="help" icon={<QuestionCircleOutlined />}>
                {t('menu.help')}
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {userData?.roles?.includes("Administrator") && userData?.mode !== "Desktop" && (
            <Menu.SubMenu
              icon={<SafetyOutlined />}
              key="security"
              title={t('menu.security')}
              popupClassName={openKeys.find(x => x === "security") ? null : "hidden"}
            >
              <Menu.Item
                key="security/authentication"
                icon={<SafetyCertificateOutlined />}
              >
                {t('menu.authentication')}
              </Menu.Item>
              <Menu.Item key="security/accessControls" icon={<TeamOutlined />}>
                {t('menu.accessControls')}
              </Menu.Item>
              <Menu.Item key="security/identities" icon={<IdcardOutlined />}>
                {t('menu.identities')}
              </Menu.Item>
              <Menu.Item key="security/roles" icon={<TeamOutlined />}>
                {t('menu.roles')}
              </Menu.Item>
              <Menu.Item key="security/tokens" icon={<BarcodeOutlined />}>
                {t('menu.tokens')}
              </Menu.Item>

            </Menu.SubMenu>
          )}
        </Menu>
      </Scrollbars>
    </Layout.Sider>
  );
}
