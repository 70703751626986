import React from "react";
import {
  BellFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Empty,
  List,
  Space,
  Tag,
  Typography,
} from "antd";
import { useQuery } from "react-query3";
import { NotificationLevel, NotificationQuery } from "types";
import { formatDate, toRelativeUrl } from "components/utils/utils";
import queryClient from "components/utils/queryClient";
import DeleteAllNotification from "./DeleteAllNotification";
import DeleteNotificationButton from "./DeleteNotificationButton";
import Scrollbars from "rc-scrollbars";
import { presetPrimaryColors } from "@ant-design/colors";
import { Link } from "react-router-dom";

const NotificationTag = ({ notification }) => {
  switch (notification?.level) {
    case NotificationLevel.Information:
      return <Tag color={"blue"}>Info</Tag>
    case NotificationLevel.Warning:
      return <Tag color={"yellow"}>Warning</Tag>
    case NotificationLevel.Error:
      return <Tag color={"red"}>Error</Tag>
    default:
      return <></>
  }
}

export default function Notification() {
  const { data } = useQuery<NotificationQuery>("/notification/last", {
    initialData: () => {
      return queryClient.getQueryData("/notification/last");
    },
    keepPreviousData: true,
  });

  const [visible, setVisible] = React.useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const errors = data?.page.find(m => m.level === NotificationLevel.Error);

  return (
    <Dropdown
      placement="bottomRight"
      //@ts-ignore
      destroyPopupOnHide={true}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
      overlay={
        <Card
          bordered={false}
          extra={
            [
              <DeleteAllNotification
                hidden={data?.page.length < 1}
                size="small"
                style={{ marginLeft: '10px' }}
              />,
              <Link to={toRelativeUrl("/admin/notifications")}><Button size="small" style={{ marginLeft: '10px' }} icon={<EyeOutlined />}>View All</Button></Link>
            ]

          }
        >
          <List size="small" itemLayout="horizontal">
            {data?.page.length > 0 ? (
              <Scrollbars autoHide hideTracksWhenNotNeeded autoHeight>
                {data?.page.map((item, index) => (
                  <List.Item
                    actions={[
                      <DeleteNotificationButton
                        resource={item}
                        size="small"
                        type="text"
                        icon={<DeleteFilled />}
                      />,
                    ]}
                  >
                    <Card.Meta
                      description={
                        <Space
                          style={{ width: "100%" }}
                          direction="vertical"
                          size={2}
                        >
                          {item.description}
                          <Typography.Paragraph
                            type="secondary"
                            style={{ fontSize: 12 }}
                          >
                            {formatDate(item?.createdTime, "L LT")}{" "}
                            <Link
                              to={toRelativeUrl(`/admin/security/identities/${item?.identity?.id}`)}
                            >
                              <Typography.Link>
                                {item?.createdBy}
                              </Typography.Link>
                            </Link>
                          </Typography.Paragraph>
                        </Space>
                      }
                      title={<Space>
                        <NotificationTag notification={item} />
                        {item?.title}
                      </Space>}
                    />
                  </List.Item>
                ))}
              </Scrollbars>
            ) : (
              <Empty />
            )}
          </List>
        </Card>
      }
    >
      <Button
        type="text"
        icon={
          <Badge
            count={data?.total}
            showZero={false}
            style={{ backgroundColor: errors ? presetPrimaryColors["red"] : presetPrimaryColors["blue"] }}
            size="small"
          >
            {errors ? <ExclamationCircleOutlined /> : <BellFilled />}
          </Badge>
        }
      />
    </Dropdown>
  );
}
