const lang = {
    translation: {
        common: {
            home: '🥳Home',
            endpoints: '🥳Endpoints',
            environments: '🥳Environments',
            documentation: '🥳Documentation',
            license: '🥳License',
            licensed: "🥳Licensed",
            role: '🥳Role',
            tag: '🥳Tag',
            unlicensed: '🥳Unlicensed',
            pages: '🥳Pages',
            node: '🥳Node'
        },
        accessControl: {
            pageTitle: '🥳Access Controls',
            pageDescription: '🥳Access controls for elements within PowerShell Universal.',
            create: '🥳Create Access Control',
            createTitle: '🥳Create new access control',
            accessControlType: '🥳Access Control Type',
            objectId: '🥳Object ID',
            objectType: '🥳Object Type',
            accessType: '🥳Access Type'
        },
        home: {
            pageDescription: '🥳Information about your PowerShell Universal environment.',
            jobsRun: '🥳Jobs run today',
            jobsFailed: '🥳Jobs failed today',
            jobsRunning: '🥳Currently Running Jobs',
            jobsWaiting: '🥳Jobs waiting on Feedback',
            jobsScheduled: '🥳Scheduled Jobs',
            dashboardsRunning: '🥳Running Dashboards',
            dashboardSessions: '🥳Dashboard Sessions'
        },
        licensing: {
            tooltipDescription: '🥳Some features of PowerShell Universal are not available.'
        },
        menu: {
            home: '🥳Home',
            apis: '🥳APIs',
            endpoints: "🥳Endpoints",
            automation: '🥳Automation',
            scripts: '🥳Scripts',
            triggers: '🥳Triggers',
            jobs: '🥳Jobs',
            schedules: '🥳Schedules',
            terminals: '🥳Terminals',
            fileAssociations: '🥳File Associations',
            userInterfaces: '🥳User Interfaces',
            dashboards: '🥳Dashboards',
            pages: '🥳Pages',
            platform: '🥳Platform',
            debugging: '🥳Debugging',
            publishedFolders: '🥳Published Folders',
            modules: '🥳Modules',
            templates: '🥳Templates',
            rateLimiting: '🥳Rate Limiting',
            variables: '🥳Variables',
            settings: '🥳Settings',
            general: '🥳General',
            configurations: '🥳Configurations',
            environments: '🥳Environments',
            license: '🥳License',
            loginPage: '🥳Login Page',
            tags: '🥳Tags',
            help: '🥳Help',
            security: '🥳Security',
            authentication: '🥳Authentication',
            accessControls: '🥳Access Controls',
            identities: '🥳Identities',
            protect: '🥳Protect',
            roles: '🥳Roles',
            tokens: '🥳Tokens',
            translations: "🥳Translations"
        },
        themes: {
            light: '🥳Light',
            dark: '🥳Dark'
        }
    }
}
export default lang;