const lang = {
    translation: {
        common: {
            home: 'Casa',
            documentation: 'Documentazione',
            licensed: "Licenza",
            role: 'Ruolo',
            tag: 'Cartellino',
            unlicensed: 'Non permesso',
            node: 'Node'
        },
        accessControl: {
            pageTitle: 'Controlli di accesso',
            pageDescription: 'Controlli di accesso per gli elementi all\'interno di PowerShell Universal.',
            create: 'Creare il controllo di accesso',
            createTitle: 'Creare un nuovo controllo di accesso',
            accessControlType: 'Tipo di controllo di accesso',
            objectId: 'Identificatore oggetto',
            objectType: 'Tipo di oggetto',
            accessType: 'Tipo di accesso'
        },
        home: {
            pageDescription: 'Informazioni sull\'ambiente PowerShell Universal.'
        },
        menu: {
            home: 'Casa',
            automation: 'Automazione',
            jobs: 'Lavori',
            schedules: 'Orari',
            terminals: 'Terminali',
            fileAssociations: 'Associazioni di file',
            userInterfaces: 'Interfacce utente',
            pages: 'Pagine',
            platform: 'Piattaforma',
            publishedFolders: 'Cartelle pubblicate',
            modules: 'Moduli',
            templates: 'Modelli',
            rateLimiting: 'Limitazione della velocità',
            variables: 'Variabili',
            settings: 'Impostazioni',
            general: 'Generale',
            configurations: 'Configurazioni',
            environments: 'Ambienti',
            license: 'Licenza',
            loginPage: 'Pagina di accesso',
            help: 'Guida',
            security: 'Sicurezza',
            authentication: 'Autenticazione',
            accessControls: 'Controlli di accesso',
            identities: 'Identità',
            protect: 'Proteggere',
            roles: 'Ruoli'
        },
        licensing: {
            tooltipDescription: 'Alcune funzionalità di PowerShell Universal non sono disponibili.'
        },
        themes: {
            light: 'Leggero',
            dark: 'Oscuro'
        }
    }
}
export default lang;