import React from 'react';
import { Card, Form, Input, Menu, Modal } from 'antd';
import { GetProps } from './Component';
import { ComponentPropertyType, IComponentProperty } from './ComponentProperty';
import DataSourceSelector from './properties/dataSource';
import Number from './properties/number';
import Checkbox from './properties/checkbox';
import PSUTarget from './properties/target';
import PSUSelect from './properties/select';
import PSUIcon from './properties/icon';
import TableColumns from './properties/tableColumns';
import FormFields from './properties/formFields';
import TargetParameters from './properties/targetParameters';
import Variable from './properties/variable';
import Api from './properties/api';
import RefreshComponents from './properties/refreshComponent';

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function ComponentProperties({ component, form }) {

  const componentProps = GetProps(component);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }


  const grouped = groupBy(componentProps, component => component.category);
  const categories = componentProps.filter(m => m.category).map(m => m.category).filter(onlyUnique);

  const [activeTab, setActiveTab] = React.useState(categories.length ? categories[0] : "required");

  const renderField = (prop: IComponentProperty) => {
    switch (prop.type) {
      case ComponentPropertyType.TableColumns:
        return <TableColumns key={prop.name} {...prop} component={component} form={form} />
      case ComponentPropertyType.Target:
        return <PSUTarget key={prop.name} {...prop} component={component} />
      case ComponentPropertyType.Boolean:
        return <Checkbox key={prop.name} {...prop} />
      case ComponentPropertyType.Number:
        return <Number key={prop.name} {...prop} />
      case ComponentPropertyType.Select:
        return <PSUSelect key={prop.name} {...prop} />
      case ComponentPropertyType.Icon:
        return <PSUIcon key={prop.name} {...prop} />
      case ComponentPropertyType.String:
        return (
          <Form.Item
            key={prop.name}
            label={prop.displayName}
            name={prop.name}
            rules={[{ required: prop.required }]}
            tooltip={prop.description}
          >
            {prop.multiline ? <Input.TextArea /> : <Input />}
          </Form.Item>
        )
      case ComponentPropertyType.DataSource:
        return <DataSourceSelector key={prop.name} {...prop} component={component} form={form} />
      case ComponentPropertyType.FormFields:
        return <FormFields key={prop.name} {...prop} component={component} form={form} />
      case ComponentPropertyType.TargetParameters:
        return <TargetParameters key={prop.name} {...prop} component={component} form={form} />
      case ComponentPropertyType.Variable:
        return <Variable key={prop.name} {...prop} component={component} form={form} />
      case ComponentPropertyType.RefreshComponents:
        return <RefreshComponents key={prop.name} {...prop} />
      case ComponentPropertyType.Api:
        return <Api key={prop.name} {...prop} />

    }
  }

  const requiredFields = componentProps.filter(m => m.required && !m.category).map(renderField);
  const optionalFields = componentProps.filter(m => !m.required && !m.category).map(renderField);

  const menuItems = [];

  let selectedKey = activeTab;

  if (requiredFields.length) {
    selectedKey = selectedKey ?? "required";
    menuItems.push({
      key: 'required',
      label: 'Required'
    })
  } else {
    selectedKey = selectedKey ?? categories[0];
  }

  categories?.forEach(category => {
    menuItems.push({
      key: category,
      label: category
    });
  })

  if (optionalFields.length > 0) {
    menuItems.push({
      key: 'optional',
      label: 'Optional'
    })
  }

  return <>
    <Menu mode="horizontal" items={menuItems} defaultSelectedKeys={[selectedKey]} onClick={({ key }) => setActiveTab(key)} activeKey={activeTab} />
    <Card hidden={activeTab !== "required"}>
      {requiredFields}
    </Card>
    {categories.map(group => {
      return <Card hidden={activeTab !== group}>{grouped.get(group).map(renderField)}</Card>
    })}
    <Card hidden={activeTab !== "optional"}>
      {optionalFields}
    </Card>


  </>
}

export default function ComponentPropertiesModal(props) {
  const [form] = Form.useForm();
  const { visible, setVisible, component, setComponent } = props;

  const onFinish = (values: any) => {
    var updatedComponent = { ...component, ...values };
    setComponent(updatedComponent);
    setVisible(false);
  }

  const submit = () => {
    form.validateFields()
      .then(values => {
        form.resetFields();
        onFinish(values);
      })
  }

  return (
    <>
      <Modal
        title="Properties"
        width="50vw"
        closable={false}
        maskClosable={false}
        onCancel={() => setVisible(false)}
        onOk={submit}
        okText="Save"
        visible={visible}
      >
        {props.description}
        <Form name="properties" initialValues={component} onFinish={onFinish} form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          labelAlign='left'
        >
          <ComponentProperties component={component} form={form} />
        </Form>
      </Modal>
    </>
  );
};
