import { AppToken } from "../../../types";
import { CustomSchema } from "./types";

export const tokenSchema: CustomSchema<AppToken>[] = [
  {
    name: "identity",
    label: "Identity",
    component: "select",
    selectOptions: 'identity',
    required: true,
    requiredRole: 'Administrator'
  },
  {
    name: "role",
    label: "Role",
    component: "staticRole",
    required: true,
    requiredRole: 'Administrator'
  },
  {
    name: "expiration",
    label: "Expiration",
    component: "expirationTime",
    required: true
  },
  {
    name: "description",
    label: "Description",
    component: "input",
    required: false,
  },
];
