import { LoggingTarget } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const loggingTargetSchema: CustomSchema<LoggingTarget>[] = [
    {
        name: "type",
        label: "Type",
        tooltip: "The logging target type.",
        component: "select",
        options: [
            { title: 'File', value: "File" },
            { title: 'Database', value: "Database" },
            { title: 'PowerShell', value: "PowerShell" },
            { title: 'TCP', value: "TCP" },
            { title: 'UDP', value: "UDP" },
            { title: 'HTTP', value: "HTTP" },
        ],
        initialValue: "File"
    },
    {
        name: "level",
        label: "Level",
        tooltip: "The level that this logging target accepts.",
        component: "select",
        options: [
            { title: 'Verbose', value: "Verbose" },
            { title: 'Debug', value: "Debug" },
            { title: 'Information', value: "Information" },
            { title: 'Warning', value: "Warning" },
            { title: 'Error', value: "Error" }
        ],
        initialValue: "Information"
    },
    {
        name: "scope",
        label: "Scope",
        tooltip: "The scope that this logging target accepts.",
        component: "select",
        options: [
            { title: 'User', value: "User" },
            { title: 'System', value: "System" },
        ],
        initialValue: "User"
    },
    {
        name: "feature",
        label: "Feature",
        component: "input",
        tooltip: "The feature that this logging target accepts."
    },
    {
        name: "resource",
        label: "Resource",
        component: "input",
        tooltip: "The resource that this logging target accepts."
    },
    {
        uniqe: false,
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.type !== curValues.type;
        },
        children: ({ getFieldValue }) => {
            if (getFieldValue("type") === "File") {
                return (
                    <Field
                        component="input"
                        name={["properties", "path"]}
                        label="File Path"
                        tooltip="The file path to log to."
                        preserve={false}
                    />
                );
            }
        },
    },
    {
        uniqe: false,
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.type !== curValues.type;
        },
        children: ({ getFieldValue }) => {
            if (getFieldValue("type") === "Database") {
                return (
                    <Field
                        component="input"
                        name={["properties", "connectionString"]}
                        label="Connection  String"
                        tooltip="The connection string of the database to log to."
                        preserve={false}
                    />
                );
            }
        },
    },
    {
        uniqe: false,
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.type !== curValues.type;
        },
        children: ({ getFieldValue }) => {
            if (getFieldValue("type") === "TCP" || getFieldValue("type") === "UDP") {
                return (
                    <Field
                        component="input"
                        name={["properties", "hostName"]}
                        label="Host Name"
                        tooltip="The host name of the server to log to."
                        preserve={false}
                    />
                );
            }
        },
    },
    {
        uniqe: false,
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.type !== curValues.type;
        },
        children: ({ getFieldValue }) => {
            if (getFieldValue("type") === "TCP" || getFieldValue("type") === "UDP") {
                return (
                    <Field
                        component="input"
                        name={["properties", "port"]}
                        label="Port"
                        tooltip="The port of the server to log to."
                        preserve={false}
                    />
                );
            }
        },
    },
    {
        uniqe: false,
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.type !== curValues.type;
        },
        children: ({ getFieldValue }) => {
            if (getFieldValue("type") === "HTTP") {
                return (
                    <Field
                        component="input"
                        name={["properties", "url"]}
                        label="URL"
                        tooltip="The URL to log to."
                        preserve={false}
                    />
                );
            }
        },
    },
];
