const lang = {
    translation: {
        common: {
            home: 'Home',
            endpoints: 'Endpoints',
            environments: 'Environments',
            documentation: 'Documentation',
            license: "License",
            licensed: "Licensed",
            developerLicense: "Developer License",
            developerLicenseDescription: "This instance is using a developer license and should not be used in production.",
            role: 'Role',
            tag: 'Tag',
            unlicensed: 'Unlicensed',
            pages: 'Pages',
            node: 'Node',
            processes: 'Processes',
            uptime: 'Uptime'
        },
        accessControl: {
            pageTitle: 'Access Controls',
            pageDescription: 'Access controls for elements within PowerShell Universal.',
            create: 'Create Access Control',
            createTitle: 'Create new access control',
            accessControlType: 'Access Control Type',
            objectId: 'Object ID',
            objectType: 'Object Type',
            accessType: 'Access Type'
        },
        home: {
            pageDescription: 'Information about your PowerShell Universal environment.',
            jobsRun: 'Jobs run in the last 24 hours',
            jobsFailed: 'Jobs failed today',
            jobsRunning: 'Currently Running Jobs',
            jobsWaiting: 'Jobs Waiting on Feedback',
            jobsScheduled: 'Scheduled Jobs',
            dashboardsRunning: 'Running Dashboards',
            sessions: 'User Sessions',
            healthOk: "Passing Health Checks",
            healthWarnings: "Health Checks with Warnings",
            healthErrors: "Failing Health Checks",
        },
        licensing: {
            tooltipDescription: 'Some features of PowerShell Universal are not available.'
        },
        menu: {
            apps: 'Apps',
            components: 'Components',
            themes: 'Themes',
            home: 'Home',
            apis: 'APIs',
            endpoints: 'Endpoints',
            endpointDocumentation: 'Documentation',
            eventHubs: "Event Hubs",
            automation: 'Automation',
            scripts: 'Scripts',
            triggers: 'Triggers',
            jobs: 'Jobs',
            schedules: 'Schedules',
            terminals: 'Terminals',
            fileAssociations: 'File Associations',
            userInterfaces: 'User Interfaces',
            dashboards: "Dashboards",
            dashboardPages: "Pages",
            pages: 'Pages',
            platform: 'Platform',
            debugging: 'Debugging',
            publishedFolders: 'Published Folders',
            modules: 'Modules',
            templates: 'Templates',
            rateLimiting: 'Rate Limiting',
            variables: 'Variables',
            settings: 'Settings',
            general: 'General',
            configurations: 'Configurations',
            environments: 'Environments',
            license: 'License',
            loginPage: 'Login Page',
            help: 'Help',
            tags: 'Tags',
            security: 'Security',
            authentication: 'Authentication',
            accessControls: 'Access Controls',
            identities: 'Identities',
            protect: 'Protect',
            roles: 'Roles',
            tokens: 'Tokens',
            translations: "Translations",
            logging: "Logging",
            serviceCatalog: "Service Catalog",
            services: "Services"
        },
        themes: {
            light: 'Light',
            dark: 'Dark'
        }
    }
}
export default lang;