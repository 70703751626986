import { Typography } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import Markdown from 'react-markdown';

export const Props : Array<IComponentProperty>  = [
    { "name": "id", "type": ComponentPropertyType.String , "required": true, "description": "The ID for this typography", displayName: "Id" },
    { "name": "value", "type": ComponentPropertyType.String , "required": true, "description": "The text to display", displayName: "Text" },
    { "name": "code", "type": ComponentPropertyType.Boolean , "required": false, "description": "Display this text as code.", displayName: "Code" },
    { "name": "copyable", "type": ComponentPropertyType.Boolean , "required": false, "description": "Whether this text is copyable", displayName: "Copyable" },
    { "name": "textType", "type": ComponentPropertyType.Select , "required": false, "description": "The type of text to display.", displayName: "Type", options: [
        { value: null, text: "Default" },
        { value: "secondary", text: "Secondary" },
        { value: "success", text: "Success" },
        { value: "warning", text: "Warning" },
        { value: "danger", text: "Danger" }	
    ] },
    {
        "name": "contentType", "type": ComponentPropertyType.Select, "required": false, "description": "The formatting of the content.", displayName: "Content Type", options: [
            { value: "plaintext", text: "Plaintext" },
            { value: "markdown", text: "Markdown" },
            { value: "html", text: "HTML" },
        ], category: 'Basic'
    },
]

export const Constructor = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Paragraph, PowerShellUniversal',
        "value": "Paragraph",
        "type": "Paragraph",
        "contentType": "plaintext",
        "id": uuidv4(),
        "level": "1"
    }
}

export function Component(props) {
    let { value, contentType } = props;

    if (contentType === 'markdown') {
        value = <Markdown children={value} />
    }
    else if (contentType === 'html') {
        value = <div dangerouslySetInnerHTML={{ __html: value }}></div>;
    }

    return <Typography.Paragraph code={props.code} type={props.textType}>{value}</Typography.Paragraph>
}