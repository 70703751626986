import React from "react";
import { Form, Input, message, Modal } from "antd";
import { useMutation } from "components/utils/queryClient";

export default function ResetPasswordModal({ identity, open, setOpen }) {
    const [form] = Form.useForm();
    const { mutateAsync } = useMutation();

    const onReset = (values) => {
        mutateAsync(
            {
                key: `/identity/password`,
                action: "update",
                name: identity.name,
                credentialvault: identity.credentialVault,
                ...values,
            },
            {
                onSuccess: () => {
                    form.resetFields();
                    message.success("Updated password successfully.");
                    setOpen(false)
                },
            },
        )
    }

    return (
        <Modal
            title={`Reset Password for ${identity?.name}`}
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => {
                form
                    .validateFields()
                    .then(async values => {
                        if (values.password !== values.confirmPassword) {
                            message.error("New passwords do not match.");
                            return;
                        }

                        await onReset(values);
                    })
                    .catch(info => {
                        message.error(info);
                    });
            }}
        >
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                layout="vertical"
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    label="Old Password"
                    name="oldPassword"
                    rules={[{ required: true, message: 'Please input your password.' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="New Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password.' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[{ required: true, message: 'Please input your password.' }]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
}
