import React from "react";
import { Layout, Typography } from "antd/es";
const { Footer: AntFooter } = Layout;

export default function Footer() {
  return (
    <AntFooter
      style={{
        textAlign: "center",
        backgroundColor: "transparent",
        maxHeight: 48,
        padding: "0 0 8px 0",
        fontSize: 16,
      }}
    >
      <Typography.Paragraph>
        Copyright 2024 Ironman Software, LLC
      </Typography.Paragraph>
    </AntFooter>
  );
}
