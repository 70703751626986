import en from './en';
import it from './it';
import test from './test'

const translations = {
    en,
    it,
    test
};

export default translations;