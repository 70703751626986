import React from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Switch } from "antd";

export default function ThemeToggle({
  checkedChildren,
  unCheckedChildren,
  ...props
}: import("antd/es").SwitchProps) {
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();

  const onChange = (checked: boolean) => {
    sessionStorage.setItem('theme', checked ? "dark" : "light");
    localStorage.setItem("theme", checked ? "dark" : "light");
    switcher({ theme: checked ? themes.dark : themes.light });
  };

  if (status === "loading") return null

  return (
    <Switch
      {...props}
      checkedChildren={checkedChildren || "Yes"}
      unCheckedChildren={unCheckedChildren || "No"}
      defaultChecked={currentTheme === "dark" ? true : false}
      checked={currentTheme === "dark"}
      onChange={onChange}
    />
  );
}
