import { Tag as TagType } from "../../types";

export function convertToAntTag(tags) {
  return (
    tags !== null &&
    tags?.map &&
    tags?.map((tag: TagType) => ({
      label: tag?.name,
      color: tag?.color,
      value: tag?.name,
      key: tag?.name,
    }))
  );
}
