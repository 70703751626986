import React, { useState } from "react";
import { Button, Space, Alert } from "antd";
import useAppStore from "components/context/app/Hooks";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import queryClient, { useMutation } from "components/utils/queryClient";
import { message } from "antd/es";
import RoleGuard from "components/standalone/role-guard";
import { useQuery } from "react-query3";
import { Link } from "react-router-dom";
import { toRelativeUrl } from "components/utils/utils";
import { GitChange, GitStatusResult } from "types";

export default function GitEditButton() {
    const { userData, isEditing } = useAppStore();
    const [startingEdit, setStartingEdit] = useState(false);

    const { mutateAsync } = useMutation();

    const { data: status } = useQuery<GitStatusResult>("/gitstatus/status");
    const { data: changes } = useQuery<GitChange[]>("/gitstatus/edit/changes");

    const onOk = React.useCallback(
        async () => {
            setStartingEdit(true);
            await mutateAsync({ key: "/gitstatus/edit", action: "create" }, {
                onSuccess: () => {
                    setStartingEdit(false);
                    queryClient.invalidateQueries("/gitstatus/edit");
                },
                onError: () => {
                    setStartingEdit(false);
                    message.error("Failed to start editing.");
                }
            })

        }, [mutateAsync]);

    if (!userData?.manualGitMode) return <></>

    if (status === GitStatusResult.Conflict) {

        return <Alert type="error" message="There are conflicts in your repository. Please resolve them before committing." showIcon action={<Link to={toRelativeUrl("/admin/settings/git/commit")}><Button size="small" danger>Resolve Conflict</Button></Link>} />
    }

    return <>
        {!isEditing && (
            <RoleGuard requiredRoles={["Administrator", "Operator"]} allowedWithOneWayGitSync={false} noDemo allowWhenNotEditing={true}>
                <Button icon={<EditOutlined />} onClick={onOk} loading={startingEdit}>Edit</Button>
            </RoleGuard>
        )}
        <Space direction="horizontal" hidden={!isEditing}>
            <Alert type="warning" message="Git sync does not pull during edits. Save or discard changes to allow git sync to pull." showIcon />
            <Link to={toRelativeUrl("/admin/settings/git/commit")}><Button title="Changes" icon={<SaveOutlined />}>{`Changes (${changes?.length})`}</Button></Link>
        </Space>
    </>
}
