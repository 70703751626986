import { RateLimit } from "../../../types";
import { CustomSchema } from "./types";

export const ratelimitSchema: CustomSchema<RateLimit>[] = [
  {
    name: "method",
    label: "Method",
    component: "methodSelector",
    required: false,
    uniqe: false,
    initialValue: "*",
    tooltip: "The HTTP method to rate limit."
  },
  {
    name: "endpoint",
    label: "Endpoint",
    component: "input",
    tooltip: "The endpoint or pattern to rate limit. Use wildcards for patterns. Use the format {HTTP_VERB}:{PATH}",
    required: true,
    uniqe: false,
  },
  {
    name: "limit",
    label: "Limit",
    component: "inputNumber",
    initialValue: 100,
    uniqe: false,
    tooltip: "The number of invocations over the time period."
  },
  {
    name: "period",
    label: "Period",
    component: "period",
    required: true,
    initialValue: "00:30:00",
    uniqe: false,
    tooltip: "The time period in seconds."
  },
];
