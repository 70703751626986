import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { Link } from 'react-router-dom';
import useDataSource from '../DataSource';
import { Card } from 'antd';
import Markdown from 'react-markdown';

export const Props: Array<IComponentProperty> = [
    { "name": "id", "type": ComponentPropertyType.String, "required": false, "description": "The ID for this chart", displayName: "Id" },
    { "name": "title", "type": ComponentPropertyType.String, "required": false, "description": "Title for this card.", displayName: "Title", category: 'Basic' },
    { "name": "content", "type": ComponentPropertyType.String, multiline: true, "required": false, "description": "Content for this card.", displayName: "Content", category: 'Basic' },
    {
        "name": "contentType", "type": ComponentPropertyType.Select, "required": false, "description": "The formatting of the content within the card.", displayName: "Content Type", options: [
            { value: "plaintext", text: "Plaintext" },
            { value: "markdown", text: "Markdown" },
            { value: "html", text: "HTML" },
        ], category: 'Basic'
    },
    { "name": "dataSource", "type": ComponentPropertyType.DataSource, "required": false, "description": <>The <Link to="/admin/automation/scripts" target="_blank">script</Link> or <Link to="/admin/apis/endpoints" target="_blank">API</Link> data source for this chart.</>, displayName: "Data Source", category: "Data Source" },
]

export const Constructor = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Card, PowerShellUniversal',
        "type": "Card",
        "id": uuidv4(),
    }
}

export function Component(props) {
    const { data, isLoading } = useDataSource(props);

    if (isLoading) {
        return <div />
    }

    let { title, content, contentType } = props;

    if (data) {
        let obj = data;
        if (Array.isArray(obj)) {
            obj = obj[0];
        }

        if (obj) {
            Object.keys(obj).forEach(key => {
                const variable = "$" + key;
                title = title?.replace(variable, obj[key]);
                content = content?.replace(variable, obj[key]);
            })
        }
    }


    if (contentType === 'markdown') {
        content = <Markdown children={content} />
    }
    else if (contentType === 'html') {
        content = <div dangerouslySetInnerHTML={{ __html: content }}></div>;
    }

    return <Card title={title} style={{ wordBreak: 'break-word' }}>{content}</Card>
}