import { Identity } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const identitySchema: CustomSchema<Identity>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    uniqe: true,
    rules: [{
      required: true,
      message: "Name can't be empty."
    }]
  },
  {
    name: "roles",
    label: "Roles",
    component: "roleTags"
  },
  {
    name: "localAccount",
    label: "Local Account",
    tooltip: "Creates a local account in the selected vault.",
    component: "switch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
  },
  {
    uniqe: false,
    noStyle: true,
    required: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.localAccount !== curValues.localAccount;
    },
    children: ({ getFieldValue }) => {
      let localAccount = getFieldValue("localAccount");
      return localAccount ? (
        <Field
          component="password"
          name="password"
          label="Password"
          preserve={false}
          tooltip="The password for the local account."
        />
      ) : null;
    },
  },
  {
    name: "credentialVault",
    hidden: true,
    noStyle: true
  },
  {
    uniqe: false,
    noStyle: true,
    required: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.localAccount !== curValues.localAccount;
    },
    children: ({ getFieldValue }) => {
      let localAccount = getFieldValue("localAccount");
      return localAccount ? (
        <Field
          component="vault"
          name="credentialVault"
          label="Credential Vault"
          preserve={false}
          tooltip="The vault to store the password in."
        />
      ) : null;
    },
  }
];
