import { Form, Select } from "antd";
import React, { useContext } from "react";
import { VariableContext } from './../VariableContext';

export default function RefreshComponent(props) {
    const { getComponents } = useContext(VariableContext);

    return (
        <Form.Item
            label={props.displayName}
            name={props.name}
            rules={[{ required: props.required }]}
            tooltip={props.description}
        >
            <Select mode="multiple">
                {getComponents().map(m => <Select.Option value={m}>{m}</Select.Option>)}
            </Select>
        </Form.Item>
    )
}