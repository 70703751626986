import { DashboardPage } from "../../../types";
import { CustomSchema } from "./types";

export const dashboardPageSchema: CustomSchema<DashboardPage>[] = [
    {
        name: "name",
        label: "Name",
        component: "input",
        required: true,
        uniqe: true,
        tooltip: "The name of the page. This is used to reference the page in your dashboard.",
    },
    {
        name: "title",
        label: "Title",
        component: "input",
        tooltip: "The title of the page. This is used to display the page in the navigation bar. This will be the name of the page, if not specified.",
    },
    {
        name: "description",
        label: "Description",
        component: "input",
        tooltip: "The description of the page. This will be displayed in the admin console.",
    },
    {
        name: "template",
        label: "Template",
        component: "dashboardPageTemplate",
        tooltip: "A template to use when creating this page.",
    },
    {
        name: ["icon", "name"],
        label: "Icon",
        component: "fontAwesomeIcon",
        tooltip: "The icon to display in the navigation bar.",
        hideInCreateMode: true,
    },
    {
        name: "url",
        label: "URL",
        component: "input",
        tooltip: "The URL of the page. This will be the name of the page, if not specified..",
    },
    {
        name: "generated",
        label: "Use Designer",
        component: "switch",
        valuePropName: "checked",
        tooltip: "If this is checked, the page will be generated using the App Page Designer. If this is unchecked, you will need to write the page yourself.",
    },
    {
        name: "role",
        label: "Roles",
        component: "roleTags",
        tooltip: "The roles that can access this page.",
        hideInCreateMode: true,
    },
    {
        name: "defaultHomePage",
        label: "Default Home Page",
        component: "switch",
        valuePropName: "checked",
        tooltip: "If this is checked, this page will be the default home page.",
        hideInCreateMode: true,
    },
    {
        name: "headerPosition",
        label: "Header Position",
        component: "select",
        options: [
            { title: "Absolute", value: "absolute" },
            { title: "Fixed", value: "fixed" },
            { title: "Relative", value: "relative" },
            { title: "Static", value: "static" },
            { title: "Sticky", value: "sticky" },
        ],
        hideInCreateMode: true,
    },
    {
        name: "hideUserName",
        label: "Hide User Name",
        component: "switch",
        valuePropName: "checked",
        tooltip: "If this is checked, the user name will be hidden in the navigation bar.",
        hideInCreateMode: true,
    },
    {
        name: "hideNavigation",
        label: "Hide Navigation",
        component: "switch",
        valuePropName: "checked",
        tooltip: "If this is checked, the navigation bar will be hidden.",
        hideInCreateMode: true,
    },
    {
        name: "logo",
        label: "Logo",
        component: "input",
        tooltip: "The logo to display in the navigation bar.",
        hideInCreateMode: true,
    },
    {
        name: "navigationLayout",
        label: "Navigation Layout",
        component: "select",
        options: [
            { title: "Permanent", value: "permanent" },
            { title: "Temporary", value: "temporary" },
        ],
        tooltip: "The navigation layout of the page.",
        hideInCreateMode: true,
    }
];
