import React from "react";
import { Button, ButtonProps } from "antd";
import RoleGuard from "../standalone/role-guard";
import queryClient, { useMutation } from "components/utils/queryClient";
import { ClearOutlined, DeleteOutlined } from '@ant-design/icons';

type DeleteAllNotificationProps = {} & Omit<ButtonProps, "resource">;

export default function DeleteAllNotification({
  ...props
}: DeleteAllNotificationProps) {
  const { mutateAsync } = useMutation();

  return (
    <RoleGuard requiredRoles={["Administrator"]} allowedWithOneWayGitSync={true}>
      <Button
        icon={<ClearOutlined />}
        {...props}
        onClick={() =>
          mutateAsync(
            {
              key: "/notification",
              action: "delete",
            },
            {
              onSuccess: () => {
                queryClient.refetchQueries("/notification");
                queryClient.refetchQueries("/notification/last");
              },
            }
          )
        }
      >
        Mark All as Viewed
      </Button>
      <Button
        icon={<DeleteOutlined />}
        {...props}
        onClick={() =>
          mutateAsync(
            {
              key: "/notification?force=true",
              action: "delete",
            },
            {
              onSuccess: () => {
                queryClient.refetchQueries("/notification");
                queryClient.refetchQueries("/notification/last");
              },
            }
          )
        }
      >
        Delete All Notifications
      </Button>
    </RoleGuard>
  );
}
