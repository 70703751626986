import { AccessControl, AccessControlObjectType } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const accessControlSchema: CustomSchema<AccessControl>[] = [
    {
        name: "objectId",
        component: "input",
        hidden: true,
        style: { display: 'none' }
    },
    {
        name: "accessControlType",
        label: "Access Control Type",
        component: "select",
        tooltip: "The type of access control.",
        options: [
            { title: "Object", value: "object" },
            { title: "Tag", value: "tag" }
        ]
    },
    {
        noStyle: true,
        shouldUpdate: () => true,
        children: ({ getFieldValue }) => {
            return getFieldValue("accessControlType") === "object" ? (
                <Field
                    component="accessControlObjectType"
                    name="objectType"
                    label="Object Type"
                    tooltip="The type of object to apply this access control to."
                    preserve={true}
                    uniqe={false}
                />
            ) : null;
        },
    },
    {
        noStyle: true,
        shouldUpdate: () => true,
        children: ({ getFieldValue }) => {
            return getFieldValue("accessControlType") === "tag" ? (
                <Field
                    component="singleTag"
                    name="tag"
                    label="Tags"
                    tooltip="Objects with this tag will have this access control"
                    preserve={true}
                    uniqe={false}
                />
            ) : null;
        },
    },
    {
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return (
                prevValues.objectType !== curValues.objectType ||
                curValues.objectType === AccessControlObjectType.Script
            );
        },
        children: ({ getFieldValue }) => {
            return getFieldValue("objectType") === AccessControlObjectType.Script ? (
                <Field
                    component="scriptSelect"
                    name="objectId"
                    label="Script"
                    tooltip="The script to apply this access control to. You can also leave this empty for a global access control or specify a tag."
                    preserve={true}
                    uniqe={false}
                />
            ) : null;
        },
    },
    {
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return (
                prevValues.objectType !== curValues.objectType ||
                curValues.objectType === AccessControlObjectType.App
            );
        },
        children: ({ getFieldValue }) => {
            return getFieldValue("objectType") === AccessControlObjectType.App ? (
                <Field
                    component="dashboard"
                    name="objectId"
                    label="App"
                    tooltip="The app the this access control to. You can also leave this empty for a global access control or specify a tag."
                    preserve={true}
                    uniqe={false}
                />
            ) : null;
        },
    },
    {
        name: "role",
        label: "Role",
        component: "role",
        tooltip: "The role to apply this access control to."
    },
    {
        name: "type",
        label: "Access Type",
        component: "accessControlType",
        tooltip: "The type of access to provide."
    },
];
